<template>
  <div class="main-container">
    <header
      class="collapse-header"
      :class="isHavingBackground ? 'bg-class' : ''"
      v-if="!isEditCopy"
    >
      <el-button
        v-if="arrow_position === 'left'"
        :icon="show ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
        class="collapse-header-button"
        @click="show = !show"
      >
      </el-button>
      <span style="cursor: pointer" @click="show = !show">
        <slot name="title">{{ title }}</slot>
      </span>
      <el-button
        v-if="arrow_position === 'right'"
        :icon="show ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
        class="collapse-header-button-1"
        @click="show = !show"
      >
      </el-button>
      <i
        v-if="show_tip"
        class="iconfont icon-a-fenzu6"
        @click="goto"
        style="
          cursor: pointer;
          margin-left: 15px;
          font-size: 14px;
          font-weight: 300;
          line-height: 28px;
        "
      ></i>
    </header>
    <el-collapse-transition>
      <main
        v-show="show"
        :class="arrow_position === 'right' ? '' : 'collapse-main'"
      >
        <slot></slot>
      </main>
    </el-collapse-transition>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    arrow_position: {
      type: String,
      default: "left",
    },
    isHavingBackground: {
      type: Boolean,
      default: false,
    },
    isEditCopy: {
      type: Boolean,
      default: false,
    },
    show_tip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: true,
    };
  },
  methods: {
    goto(e) {
      const nowLang = localStorage.getItem("lang") || "zh";
      if (nowLang == "zh") {
        window.open(
          "https://ms.ytdevops.com/pubKnowledge/1db7562f-6f55-4d30-907c-390163fe43b8?elementId=01J27YZARE8SQQYGNWWAA537BC",
          "_blank"
        );
      } else if (nowLang == "en") {
        window.open(
          "https://ms.ytdevops.com/pubKnowledge/1db7562f-6f55-4d30-907c-390163fe43b8?elementId=01J27YZARE8SQQYGNWWAA537BC",
          "_blank"
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.collapse-header {
  text-align: left;
  font-size: 16px;
  margin-bottom: 20px;
}
.bg-class {
  background: rgba(240, 242, 245, 1);
}
.collapse-header-button {
  padding: 0;
  margin-right: 6px;
  background: rgba(229, 229, 229, 1);
}
.collapse-header-button-1 {
  border: none;
  padding: 2px;
  background: #f0f2f5;
  &:hover {
    color: #606266;
  }
  &:focus {
    color: #606266 !important;
  }
}
.collapse-main {
  margin-left: 20px;
}
</style>
