<template>
  <div>
    <div class="main-content">
      <div v-if="change_status" ref="myhideDom" class="myhide">
        <el-pagination
          background
          layout="prev,pager, next"
          :page-count="5"
          :total="layer_sum"
          :current-page="current_page - 1"
          :page-size="1"
          @current-change="handleCurrentChange1"
        >
        </el-pagination>
      </div>
      <div
        ref="mypage"
        class="mypage"
        :data-content="$t('canvas.rightClick.statusChangeForm.num')"
        :data-content2="$t('canvas.rightClick.statusChangeForm.layer')"
      >
        <el-pagination
          background
          layout="prev, pager, next"
          :page-count="5"
          :total="layer_sum"
          :current-page="current_page"
          :page-size="1"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <div style="float: left; margin-top: 30px">
      <i class="iconfont icon-add" style="cursor: pointer" @click="add_layer" />
      &nbsp;{{ $t("editFileType.tip") }}
    </div>
    <div
      style="
        height: 80vh;
        width: 100%;
        background: white;
        clear: both;
        padding-left: 26px;
        padding-top: 20px;
      "
    >
      <el-scrollbar ref="scrollPannelRef" style="height: 100%; width: 100%">
        <div
          style="
            color: rgba(48, 100, 143, 1);
            font-size: 20px;
            float: left;
            font-weight: bold;
            padding-bottom: 25px;
            cursor: pointer;
          "
          @click="backRoute"
        >
          <i class="iconfont icon-a-logout2"></i>
          {{ name }}
        </div>
        <!-- 字段部分 -->
        <div style="clear: both; padding-top: 25px; padding-bottom: 20px">
          <collapse :title="$t('editFileType.field.title')">
            <div style="text-align: center; width: 100%">
              <div class="ziduan-Header">
                <div style="padding-left: 3.5vw">
                  <el-link
                    :href="`/${get_pid()}/home/setting/file_type/field_lab`"
                    style="font-size: 14; color: #c1c1c1"
                    >{{ $t("editFileType.createFieldBtn") }}</el-link
                  >
                </div>
              </div>

              <div class="ziduan-box">
                <!-- 表头部分 -->
                <div
                  style="
                    padding-bottom: 12px;
                    clear: both;
                    padding-top: 15px;
                    width: 100%;
                    display: flex;
                    justify-content: space-around;
                  "
                >
                  <span
                    style="
                      font-size: 17px;
                      color: #707070;
                      flex: 1;
                      font-weight: bold;
                    "
                    >{{ $t("editFileType.field.table.name") }}</span
                  >
                  <span
                    style="
                      font-size: 17px;
                      color: #707070;
                      flex: 1;
                      font-weight: bold;
                    "
                    >{{ $t("editFileType.field.table.type") }}</span
                  >
                  <span
                    style="
                      font-size: 17px;
                      color: #707070;
                      flex: 1;
                      font-weight: bold;
                    "
                    >{{ $t("editFileType.field.table.required") }}</span
                  >
                  <span
                    style="
                      font-size: 17px;
                      color: #707070;
                      flex: 1;
                      font-weight: bold;
                    "
                    >{{ $t("editFileType.field.table.floatWindow") }}</span
                  >
                </div>
                <!-- 表内容部分 -->
                <div
                  class="ziduan"
                  style="
                    height: 250px;
                    clear: both;
                    width: 100%;
                    margin-bottom: 20px;
                  "
                >
                  <el-form ref="editPermissionForm" :model="form">
                    <el-scrollbar
                      ref="myscroll"
                      style="height: 250px; width: 100%"
                    >
                      <draggable
                        v-model="form.role_arr"
                        dragable="true"
                        handle=".mydrag"
                        @update="datadragEnd"
                      >
                        <transition-group>
                          <el-form-item
                            v-for="(item, index) in form.role_arr"
                            :key="index"
                            style="font-size: 13px"
                          >
                            <!-- <div class="mydrag">
                              <svg-icon icon-class="drag" />
                            </div> -->
                            <div
                              style="
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                /* show-overflow-tooltip: true; */
                                text-overflow: ellipsis;
                                text-align: left;
                                display: inline-block;
                                color: #707070;
                                vertical-align: middle;
                                /* float: left; */
                                padding-left: 10px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex: 1;
                              "
                            >
                              <div class="mydrag">
                                <i
                                  class="iconfont icon-a-huaban33"
                                  style="font-weight: 400; color: #606266"
                                />
                              </div>
                              <!-- <el-popover
                                placement="top-start"
                                max-width="150px"
                                trigger="hover"
                                :content="item.name"
                                style="width: 6vw"
                              >
                                <el-tag
                                  :key="item.name"
                                  slot="reference"
                                  style="font-size: 14px"
                                  closable
                                  @close="handleCloseziduan(item)"
                                  ><div class="el-tag-nameBox">
                                    &nbsp;{{ item.name }}
                                  </div></el-tag
                                >
                              </el-popover> -->
                              <el-tooltip
                                v-ak-tooltip-auto-show
                                effect="light"
                                placement="top-end"
                                style="flex: 1"
                              >
                                <div slot="content">{{ item.name }}</div>
                                <div>
                                  <div
                                    style="
                                      font-size: 14px;
                                      color: #333;
                                      text-align: center;
                                    "
                                    class="el-tag-nameBox"
                                  >
                                    {{ item.name }}
                                  </div>
                                </div>
                              </el-tooltip>
                              <i
                                class="el-icon-close"
                                style="flex: 1; cursor: pointer"
                                @click="handleCloseziduan(item)"
                              ></i>
                            </div>

                            <div style="width: 120px; flex: 1">
                              <span style="font-size: 13px">
                                {{ toTranslate(item.type) }}
                              </span>
                            </div>
                            <div class="checkbox">
                              <el-checkbox-group
                                v-model="item.permission"
                                style="display: inline-block; display: flex"
                                @change="edit_permission(item)"
                              >
                                <el-checkbox
                                  v-if="item.type != 'Drawio'"
                                  label="isMust"
                                  name="permission"
                                  ><br
                                /></el-checkbox>
                                <el-checkbox
                                  v-if="item.type != 'Drawio'"
                                  label="hoverShow"
                                  name="permission"
                                  ><br
                                /></el-checkbox>
                                <el-checkbox
                                  v-if="item.type == 'Drawio'"
                                  label="isMust"
                                  name="permission"
                                  ><br
                                /></el-checkbox>
                                <el-checkbox
                                  v-if="item.type == 'Drawio'"
                                  label="no"
                                  name="permission"
                                  disabled
                                  ><br
                                /></el-checkbox>
                              </el-checkbox-group>
                            </div>
                          </el-form-item>
                        </transition-group>
                      </draggable>
                    </el-scrollbar>
                  </el-form>
                </div>
                <div
                  v-if="!inputVisible"
                  style="padding-left: 3.5vw; align-self: flex-start"
                >
                  <el-button
                    style="
                      height: 34px;
                      width: 120px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                    @click="showInput"
                  >
                    <i font-size="15" class="iconfont icon-add" /><span
                      style="color: #a6a6a6"
                      >&nbsp;{{ $t("editFileType.field.newFieldBtn") }}</span
                    >
                  </el-button>
                </div>
                <!-- 新增条目部分 -->
                <div v-if="inputVisible" class="add-ziduanBox">
                  <div class="add-ziduanBox-1">
                    <el-select
                      ref="fieldSelect"
                      multiple
                      v-model="new_fields"
                      placeholder=""
                      size="small"
                      filterable
                      collapse-tags
                      @visible-change="get_fields"
                    >
                      <el-option
                        v-for="(item, index) in fields"
                        :key="index"
                        :label="item.name"
                        :value="item.fieldId"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div class="add-ziduanBox-1">
                    <el-input
                      size="small"
                      style="width: 120px"
                      placeholder=""
                      :disabled="true"
                    >
                    </el-input>
                  </div>
                  <div class="checkbox add-ziduanBox-2">
                    <el-checkbox-group v-model="add_permission">
                      <el-checkbox label="isMust" name="permission"
                        ><br
                      /></el-checkbox>
                      <el-checkbox label="hoverShow" name="permission"
                        ><br
                      /></el-checkbox>
                    </el-checkbox-group>
                  </div>
                </div>
              </div>
            </div>
          </collapse>
        </div>
        <div style="clear: both; padding-top: 25px" class="coverage-content">
          <collapse :title="$t('editFileType.coverage.title')">
            <div v-if="isFirst" class="type" style="height: 10vh; width: 100%">
              <el-scrollbar style="height: 10vh; width: 100%">
                <el-tag
                  v-for="tag in dynamicTags"
                  :key="tag.targetId"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag)"
                >
                  {{ tag.name }}
                </el-tag>

                <el-select
                  v-if="inputVisible2"
                  v-model="inputValue2"
                  ref="fieldSelect2"
                  clearable
                  filterable
                  multiple
                  collapse-tags
                  class="input-new-tag"
                  size="small"
                  value-key="targetId"
                  @visible-change="get_fileTypes"
                >
                  <el-option
                    v-for="(item, index) in fileTypes"
                    :label="item.name"
                    :value="item"
                    :key="item.targetId"
                  >
                  </el-option>
                </el-select>
                <el-button style="border: none" @click="showInput2"
                  ><i
                    class="iconfont icon-add"
                    style="font-size: 20px; color: rgb(48, 100, 143)"
                /></el-button>
              </el-scrollbar>
            </div>
            <div v-else class="type" style="height: 10vh; width: 100%">
              <el-scrollbar style="height: 10vh">
                <el-tag
                  v-for="(tag, index) in dynamicTags"
                  :key="index"
                  :disable-transitions="false"
                  @close="handleClose(tag)"
                >
                  {{ tag.name }}
                </el-tag>
              </el-scrollbar>
            </div>
          </collapse>
        </div>

        <div style="clear: both; padding-top: 25px">
          <collapse :title="$t('editFileType.workflow.title')">
            <!-- 解决current_layer异步获取数据初始传入为空 -->
            <workflow-edit
              v-if="
                current_layer &&
                show_workflow_edit &&
                form &&
                result &&
                all_level_result
              "
              ref="WorkflowEdit"
              :layer_number="current_layer"
              :pannel_height="pannel_height"
              :field_arr="form.role_arr"
              :user-list="userList"
              :workflow-is-edit-push-path="workflow_isEdit_pushPath"
              :n-o-t-e-m-p-t-y-role-arr="NOT_EMPTY_role_arr"
              :all_level_result="all_level_result"
              :current_page="current_page"
              :ischange_level="ischange_level"
              @get_workflow_position="get_workflow_position"
              @workflow_isEdit="workflow_isEdit"
              @ispush_from12="ispush_from"
              @gonext1="gonext"
              @change_level1="change_level"
            ></workflow-edit>
          </collapse>
        </div>
      </el-scrollbar>
    </div>

    <el-dialog
      :title="$t('review.tip11')"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      :show-close="true"
      width="25%"
      height="8vh"
      :before-close="handleClose_layer"
    >
      <div style="display: flex">
        <i
          style="font-size: 20px; color: rgb(255, 195, 0)"
          class="iconfont icon-jingshi-tianchong"
        ></i>
        <span style="float: left; text-align: start; padding-left: 10px">{{
          $t("editFileType.workflow.template.tip1")
        }}</span>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="delete_layer">{{
          $t("addMyColla.table.title10")
        }}</el-button>
        <el-button @click="dialogVisible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 状态转换弹窗 -->
    <el-dialog
      :title="$t('editFileType.workflow.saveForm.title')"
      :visible="status_confirm_visible"
      :close-on-click-modal="false"
      :show-close="true"
      width="25%"
      class="confirm_status_dialog"
      @close="confirm_status_dialog(false)"
    >
      <div class="status_matching">
        <div class="item title">
          <div>{{ $t("editFileType.workflow.saveForm.original") }}</div>
          <div>{{ $t("editFileType.workflow.saveForm.matched") }}</div>
        </div>
        <el-form
          ref="matching_form"
          class="matching_form"
          :model="matching_form"
        >
          <div
            v-for="(item, index) in matching_form.matching_data"
            :key="index"
            class="item"
          >
            <el-tooltip
              v-ak-tooltip-auto-show
              effect="dark"
              :content="item.label"
              placement="bottom"
            >
              <div class="old_item">
                {{ item.label }}
              </div>
            </el-tooltip>
            <el-form-item
              :prop="`matching_data.${index}.newStatusId`"
              :rules="[
                {
                  required: true,
                  message: $t('editFileType.workflow.saveForm.place'),
                  trigger: ['blur', 'change'],
                },
              ]"
            >
              <el-select
                v-model="item.newStatusId"
                class="matching_item"
                :placeholder="$t('placeholder.dropdown')"
              >
                <el-option
                  v-for="i in format_data.nodes"
                  :key="i.id"
                  :label="i.label"
                  :value="i.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="send_matching_status">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  get_filetype_detail,
  filetype_layer_add,
  fields_get,
  fields_add,
  fields_delete,
  fieldReference_modify,
  filetype_layer_delete,
  field_modify,
  field_order,
} from "@/network/fileType/index.js";
import { get_filetype } from "@/network/home/index.js";
import Collapse from "@/components/mindmap/node_detail/Collapse.vue";
import draggable from "vuedraggable";
import WorkflowEdit from "./components/WorkflowEdit";
import { mapGetters } from "vuex";
import {
  get_workflow_resource,
  set_matching_status,
} from "@/network/workflow/index.js";
export default {
  components: { Collapse, draggable, WorkflowEdit },
  props: {
    userList: {
      default: [],
    },
  },
  provide() {
    return {
      workflow_reload: this.workflow_reload,
    };
  },
  data() {
    return {
      isFirst: true,
      name: this.$store.getters.page_name,
      new_fields: [],
      current_layer: "",
      fileTypes: [],
      fields: [],
      add_permission: [], //新增字段的权限
      result: {}, //存储获得的所有层级信息
      current_page1: 1,
      dynamicTags: [],
      inputVisible: false,
      inputVisible2: false,
      inputValue2: [],
      layer_sum: this.$store.getters.page_layer, //文件类型的层级数
      current_page: 1, //当前层级
      form: {
        role_arr: [],
      },
      NOT_EMPTY_role_arr: [
        {
          name: this.$t("editFileType.workflow.template.tip2"),
          fieldId: "assignee",
        },
        {
          name: this.$t("editFileType.workflow.template.tip3"),
          fieldId: "story_point",
        },
        {
          name: this.$t("editFileType.workflow.template.tip4"),
          fieldId: "baseline_id",
        },
        {
          name: this.$t("editFileType.workflow.template.tip5"),
          fieldId: "version_id",
        },
        {
          name: this.$t("editFileType.workflow.template.tip6"),
          fieldId: "relation",
        },
      ],
      show_workflow_edit: true,
      pannel_height: null,
      workflow_isEdit_all: false,
      workflow_isEdit_pushPath: "",
      ispush_froms: false,
      ischange_level: false,
      dialogVisible: false,
      handle_data: 0,
      change_status: true,
      isHara: false,
      haraPermission: [],
      payDialogVisible: false,
      tipsText: "",
      status_confirm_visible: false,
      matching_form: {
        matching_data: [],
      },
      format_data: {
        nodes: [],
        edges: [],
      },
    };
  },
  computed: {
    all_level_result() {
      return this.result.fileTypeLayerList;
    },
  },
  watch: {
    current_page1: {
      handler() {
        if (this.workflow_isEdit_all) {
          this.$refs.WorkflowEdit.edit_unSave_dialog = true;
          this.ischange_level = true;
        } else {
          // 是否保存切换
          this.get_data(this.current_page1 - 1);
          this.current_page = this.current_page1;
          if (this.$refs.WorkflowEdit) {
            this.$refs.WorkflowEdit.isEdit = false;
          }

          this.ischange_level = false;
        }
      },
      immediate: true,
    },
  },

  mounted() {},
  methods: {
    backRoute() {
      this.$router.push(`/${this.get_pid()}/home/setting/file_type/work_flow`);
    },
    edit_permission(item) {
      let obj = item;
      obj.show = false;
      if (item.permission.indexOf("isMust") !== -1) {
        obj.required = true;
      } else {
        obj.required = false;
      }
      if (item.permission.indexOf("hoverShow") !== -1) {
        obj.show = true;
      } else {
        obj.show = false;
      }
      field_modify(
        this.get_pid(),
        this.$store.getters.page_typeid,
        this.current_layer,
        obj.fieldReferenceId,
        obj
      ).then((res) => {});
    },
    handleHaraChange() {
      if (!this.ShowHara) {
        this.$message({
          duration: 0,
          showClose: true,
          dangerouslyUseHTMLString: true,
          message: `<span>${this.$t("certificate.Please")}<a href="${
            window.location.origin
          }/profile/certificate_management?target=HARA" target="_blank" style="color: blue">${this.$t(
            "certificate.CertificateManagement"
          )}</a>${this.$t("certificate.InstallComponents")}</span>`,
        });
        this.tipsText = this.$t("certificate.HARAPurchaseTips");
        this.get_data(this.current_page1 - 1);
      } else {
        const params = {
          projectId: this.get_pid(),
          fileTypeId: this.$store.getters.page_typeid,
          layerNumber: this.current_layer,
          effective: this.isHara,
          required: false,
          show: false,
        };
        for (let i in this.haraPermission) {
          if (this.haraPermission[i] == "isMust") {
            params.required = true;
          } else if (this.haraPermission[i] == "hoverShow") {
            params.show = true;
          }
        }
        updateHaraConfig(params).then((res) => {
          // console.log(res);
        });
      }
    },

    choose_fileType(arr) {
      let id = [];
      for (let i in this.dynamicTags) {
        id.push(this.dynamicTags[i].targetId);
      }
      for (let item of arr) {
        id.push(item.targetId);
      }
      fieldReference_modify(
        this.get_pid(),
        this.$store.getters.page_typeid,
        this.current_layer,
        id
      ).then((res) => {
        this.dynamicTags.push(...arr);
        setTimeout(() => {
          this.inputVisible = false;
          this.inputVisible2 = false;
          this.inputValue2 = [];
        }, 150);
      });
    },
    get_fileTypes(flag) {
      this.fileTypes = [];
      if (flag) {
        get_filetype(this.get_pid()).then((res) => {
          let fflag = 1;
          for (let i in res) {
            if (res[i].fileTypeId == this.$store.getters.page_typeid) {
              continue;
            }
            for (let j in this.dynamicTags) {
              if (
                res[i].name == this.dynamicTags[j].name ||
                res[i].fileTypeId == this.$store.getters.page_typeid
              ) {
                fflag = 0;
                break;
              }
            }
            if (fflag)
              this.fileTypes.push({
                name: res[i].name,
                targetId: res[i].fileTypeId,
              });
            fflag = 1;
          }
        });
      } else {
        this.choose_fileType(this.inputValue2);
      }
    },
    get_fields(flag) {
      if (flag) {
        fields_get(
          this.get_pid(),
          this.$store.getters.page_typeid,
          this.current_layer
        ).then((res) => {
          this.fields = res;
        });
      } else {
        const arr = [];
        this.new_fields.forEach((item) => {
          const obj = {
            fieldId: item,
            required: false,
            show: false,
          };
          arr.push(obj);
        });
        fields_add(
          this.get_pid(),
          this.$store.getters.page_typeid,
          this.current_layer,
          arr
        ).then((res) => {
          res.forEach((item) => {
            const obj = {
              fieldReferenceId: item.fieldReferenceId,
              name: item.name,
              permission: [],
              type: item.fieldType,
              fieldId: item.fieldId,
              extraData: item.extraData,
            };
            this.result.fileTypeLayerList[this.current_page - 1].fieldList.push(
              res
            );
            this.form.role_arr.push(obj);
          });
          //this.get_data(this.current_page-1);
          this.$nextTick(() => {
            this.$refs["myscroll"].wrap.scrollTop =
              this.$refs["myscroll"].wrap.scrollHeight;
          });
          setTimeout(() => {
            this.inputVisible = false;
            this.inputVisible2 = false;
            this.inputValue2 = [];
            this.new_fields = [];
          }, 150);
        });
      }
    },
    handleCloseziduan(item) {
      fields_delete(
        this.get_pid(),
        this.$store.getters.page_typeid,
        this.current_layer,
        item.fieldReferenceId
      ).then((res) => {
        for (let i in this.form.role_arr) {
          if (this.form.role_arr[i].name == item.name) {
            this.form.role_arr.splice(i, 1);
            break;
          }
        }
      });
    },
    showInput() {
      this.new_fields = [];
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.fieldSelect.focus();
      });
    },
    showInput2() {
      this.inputVisible2 = !this.inputVisible2;
      this.$nextTick(() => {
        this.$refs.fieldSelect2.focus();
      });
    },
    set_data(num) {
      this.form.role_arr = [];
      this.dynamicTags = [];
      let list = this.result.fileTypeLayerList[num].fieldList;
      for (let i in list) {
        let per = [];
        if (list[i].required) {
          per.push("isMust");
        }
        if (list[i].show) {
          per.push("hoverShow");
        }
        const obj = {
          name: list[i].name,
          permission: per,
          type: list[i].fieldType,
          fieldReferenceId: list[i].fieldReferenceId,
          fieldId: list[i].fieldId,
          extraData: list[i].extraData,
        };
        this.form.role_arr.push(obj);
      }
      list = this.result.fileTypeLayerList[num].relationTypeList;
      this.current_layer = this.result.fileTypeLayerList[num].layerNumber;
      for (let i in list) {
        const obj = {
          name: list[i].name,
          targetId: list[i].targetId,
        };
        this.dynamicTags.push(obj);
      }
      if (this.current_page == 1) {
        this.isFirst = true;
      } else {
        this.isFirst = false;
      }
    },
    get_data(num) {
      this.form.role_arr = [];
      this.dynamicTags = [];
      get_filetype_detail(this.get_pid(), this.$store.getters.page_typeid).then(
        (rs) => {
          this.result = rs;
          this.set_data(num);
        }
      );
    },
    toTranslate(src) {
      if (src == "TEXT") {
        return this.$t("fieldLab.addNewFieldForm.fieldTypeList.text");
      } else if (src == "TEXTAREA") {
        return this.$t("fieldLab.addNewFieldForm.fieldTypeList.textarea");
      } else if (src == "NUMBER") {
        return this.$t("fieldLab.addNewFieldForm.fieldTypeList.number");
      } else if (src == "SELECT") {
        return this.$t("fieldLab.addNewFieldForm.fieldTypeList.select");
      } else if (src == "SELECT_MULTI") {
        return this.$t("fieldLab.addNewFieldForm.fieldTypeList.multiSelect");
      } else if (src == "SINGLE_USER_SELECT") {
        return this.$t("fieldLab.addNewFieldForm.fieldTypeList.user");
      } else if (src == "MULTI_USER_SELECT") {
        return this.$t("fieldLab.addNewFieldForm.fieldTypeList.multiUser");
      } else if (src == "DATE") {
        return this.$t("fieldLab.addNewFieldForm.fieldTypeList.date");
      } else if (src == "LABEL") {
        return this.$t("fieldLab.addNewFieldForm.fieldTypeList.label");
      } else if (src == "VERSION") {
        return this.$t("fieldLab.addNewFieldForm.fieldTypeList.version");
      } else if (src == "LINK") {
        return this.$t("fieldLab.addNewFieldForm.fieldTypeList.link");
      } else if (src == "DRAWIO") {
        return "Drawio";
      }
    },
    async handleCurrentChange1(val) {
      this.handle_data = val;
      let targetNum = this.all_level_result[this.handle_data - 1].layerNumber;
      let preNum = this.all_level_result[this.handle_data - 2].layerNumber;

      // 获取匹配状态
      await get_workflow_resource(
        this.get_pid(),
        this.$store.getters.page_typeid,
        preNum
      ).then((res) => {
        this.format_data.nodes = res.nodes;
      });
      // 获取原状态
      await get_workflow_resource(
        this.get_pid(),
        this.$store.getters.page_typeid,
        targetNum
      ).then((res) => {
        this.matching_form.matching_data = res.nodes.map((ele) => {
          let obj = {
            newStatusId: "",
            oldStatusId: ele.id,
            label: ele.label,
          };
          return obj;
        });
      });
      // 从原状态中筛选出匹配状态中没有的状态
      this.matching_form.matching_data =
        this.matching_form.matching_data.filter((obj1) =>
          this.format_data.nodes.every((obj2) => obj1.oldStatusId !== obj2.id)
        );
      this.dialogVisible = true;
      this.myhide_repair();
    },
    delete_layer() {
      if (this.matching_form.matching_data.length == 0) {
        filetype_layer_delete(
          this.get_pid(),
          this.$store.getters.page_typeid,
          this.result.fileTypeLayerList[this.handle_data - 1].layerNumber
        ).then((res) => {
          this.layer_sum = this.layer_sum - 1;
          this.result.fileTypeLayerList.splice(this.handle_data - 1, 1);
          this.$store.commit("SET_PAGE_LAYER", this.layer_sum);
          // 分页器跳转
          if (this.current_page1 === this.handle_data - 1) {
            this.get_data(this.current_page1 - 1);
          } else {
            this.current_page1 = this.handle_data - 1;
            this.current_page = this.handle_data - 1;
            this.$refs.WorkflowEdit.graph.dispose();
          }
          this.dialogVisible = false;
          this.myhide_repair();
        });
      } else {
        this.status_confirm_visible = true;
      }
    },
    handleClose_layer() {
      this.dialogVisible = false;
    },
    handleCurrentChange(val) {
      this.current_page1 = val;
      this.myhide_repair();
    },
    // 修复切换层级bug
    myhide_repair() {
      this.change_status = false;
      this.$nextTick(() => {
        this.change_status = true;
      });
    },
    datadragEnd(evt) {
      let list = [];
      for (let i in this.form.role_arr) {
        list.push(this.form.role_arr[i].fieldReferenceId);
      }
      field_order(
        this.get_pid(),
        this.$store.getters.page_typeid,
        this.current_layer,
        list
      ).then((res) => {});
    },
    handleClose(tag) {
      let id = [];
      for (let i in this.dynamicTags) {
        if (this.dynamicTags[i].targetId == tag.targetId) continue;
        id.push(this.dynamicTags[i].targetId);
      }
      fieldReference_modify(
        this.get_pid(),
        this.$store.getters.page_typeid,
        this.current_layer,
        id
      ).then((res) => {
        this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      });
    },

    add_layer() {
      filetype_layer_add(this.get_pid(), this.$store.getters.page_typeid).then(
        (res) => {
          this.layer_sum = this.layer_sum + 1;
          this.current_page = this.layer_sum;
          this.current_page1 = this.layer_sum;
          this.$store.commit("SET_PAGE_LAYER", this.layer_sum);
          this.get_data(this.current_page - 1);
        }
      );
    },

    back() {
      this.$router.back();
    },
    workflow_reload() {
      this.show_workflow_edit = false;

      this.$nextTick(function () {
        this.show_workflow_edit = true;
        setTimeout(() => {
          this.$refs.scrollPannelRef.wrap.scrollTop = this.pannel_height;
        }, 0);
      });
    },
    get_workflow_position() {
      this.pannel_height = this.$refs.scrollPannelRef.wrap.scrollTop;
    },
    workflow_isEdit(val) {
      this.workflow_isEdit_all = val;
    },
    ispush_from(val) {
      this.ispush_froms = val;
    },
    gonext() {
      this.current_page = this.current_page1;
      this.get_data(this.current_page1 - 1);
      this.$refs.WorkflowEdit.isEdit = false;
      this.ischange_level = false;
    },
    change_level(val) {
      this.ischange_level = val;
    },
    confirm_status_dialog(val) {
      if (!val) {
        this.$refs["matching_form"].resetFields();
        this.status_confirm_visible = false;
      } else {
        this.status_confirm_visible = true;
      }
    },
    send_matching_status() {
      this.$refs["matching_form"].validate((valid) => {
        if (valid) {
          let params = {
            projectId: this.get_pid(),
            fileTypeId: this.$store.getters.page_typeid,
            layerNumber:
              this.all_level_result[this.handle_data - 1].layerNumber,
            data: this.matching_form.matching_data,
          };
          set_matching_status({ ...params }).then(() => {
            this.status_confirm_visible = false;
            filetype_layer_delete(
              this.get_pid(),
              this.$store.getters.page_typeid,
              this.result.fileTypeLayerList[this.handle_data - 1].layerNumber
            ).then((res) => {
              this.layer_sum = this.layer_sum - 1;
              this.result.fileTypeLayerList.splice(this.handle_data - 1, 1);
              this.$store.commit("SET_PAGE_LAYER", this.layer_sum);
              // 分页器跳转到第一页
              if (this.current_page1 === this.handle_data - 1) {
                this.get_data(this.current_page1 - 1);
              } else {
                this.current_page1 = this.handle_data - 1;
                this.current_page = this.handle_data - 1;
                this.$refs.WorkflowEdit.graph.dispose();
              }
              this.dialogVisible = false;
              this.myhide_repair();
            });
          });
        } else {
          return false;
        }
      });
    },
  },
  // 监视路由离开
  beforeRouteLeave(to, from, next) {
    if (this.workflow_isEdit_all && !this.ispush_froms) {
      this.workflow_isEdit_pushPath = to.path;
      this.$refs.WorkflowEdit.edit_unSave_dialog = true;
      return false;
    } else {
      next();
    }
  },
};
</script>

<style scoped>
.mypage >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
  background: rgba(1, 76, 170, 1);
}
.mypage >>> .el-pager .number:before {
  content: attr(data-content);
}
.mypage >>> .el-pager .number:after {
  content: attr(data-content2);
}

.mypage >>> .el-pager li {
  /* width: 100px; */
  background: rgba(208, 227, 242, 1);
  font-weight: 400;
  width: 120px;
  height: 48x;

  /* margin-left: 15px;
  margin-right: 15px; */
}

.gray_line {
  background: #e5e5e5;
  width: 100px;
  height: 2px;
  float: left;
  margin: 10px 5px;
}
.el-checkbox-group >>> .el-checkbox__inner {
  width: 20px;
  height: 20px;
}
.el-checkbox-group >>> .el-checkbox__inner:hover {
  border-color: #30648f;
}
.el-checkbox-group >>> .el-checkbox__inner::after {
  border: 2px solid white;
  border-left: 0px;
  border-top: 0px;
  height: 13px;
  left: 5px;
  position: absolute;
  top: 0px;
  width: 6px;
}
.el-checkbox-group >>> .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #30648f;
  border-color: #30648f;
}
.el-checkbox-group >>> .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #30648f;
}
.ziduan >>> .el-scrollbar__wrap {
  overflow-x: hidden;
}
.ziduan >>> el-popover__reference::before {
  content: "x";
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
/deep/.el-scrollbar__wrap {
  overflow-x: auto;
}

/deep/ .el-tag .el-icon-close {
  /* position: absolute;
  top: 11px;
  right: -18px; */
  flex: 1;

  position: static;
}

/deep/ #text {
  display: none;
}
/deep/ .el-popover__reference {
  display: flex;
  align-items: center;
  width: 100%;
}

/deep/.el-button:hover {
  background: white;
}

/deep/.el-button--danger:hover {
  background: rgb(247, 137, 137);
}

.el-tag-nameBox {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  /*禁止换行显示*/
  white-space: nowrap;
}
.mydrag {
  flex: 1;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 4px;
}
.mypage {
  width: 100%;
}
.mypage >>> .el-pager {
  border-bottom: 1px solid black;
  height: 0.08rem;
  border-left: 4px solid #f0f2f5;
  border-right: 4px solid #f0f2f5;
}

/deep/.el-form-item__content {
  line-height: 27px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/* /deep/.el-form-item__content >>> div {
  flex: 1;
} */
.myhide {
  width: 100%;
  height: 100%;
}
/deep/ .myhide .btn-prev {
  opacity: 0;
  pointer-events: none;
}
/deep/ .myhide .btn-next {
  opacity: 0;
  pointer-events: none;
}
/deep/ .el-tag {
  color: #909399;
  background-color: #f4f4f5;
  border-color: #e9e9eb;
}
/deep/ .el-tag .el-tag__close {
  color: #909399;
}
/deep/.el-tag .el-tag__close:hover {
  color: #fff;
  background-color: #909399;
}
.myhide >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #f0f2f5;
  color: #f0f2f5;
}

.myhide >>> .el-pager li {
  background: #f0f2f5;
  font-weight: 400;
  flex: 1;
  /* margin-left: 15px;
  margin-right: 15px; */
  width: 120px;
  height: 48x;
  color: #f0f2f5;
  -webkit-text-fill-color: #f0f2f5;
  /* pointer-events: none; */
}
.myhide >>> .el-pager li.number:after {
  /* content: "\2715"; */
  /* content: url(../../assets/img/closePG1.png); */
  -webkit-text-fill-color: black;
  /* margin-left: 70px; */
  /* pointer-events: auto; */
  width: 25.92px;
  height: 25.92px;
  float: right;
}
.myhide >>> .el-pager li.number:first-child:after {
  content: " ";
  pointer-events: none;
}
.myhide >>> .el-pager li.number {
  pointer-events: none;
  background-image: none;
}
.myhide >>> .el-pager li.number:last-child {
  pointer-events: auto !important;
  background-image: url("../../assets/img/closeWorkflow.png") !important;
  background-image: url("../../assets/img/closeWorkflow.png") !important;
  background-repeat: no-repeat !important;
  background-position: 50% 100% !important;
  background-size: 16% 50% !important;
  font-size: 0 !important;
  cursor: pointer !important;
}
.el-form-item {
  margin-bottom: 15px;
}
</style>

<style lang="scss" scoped>
.main-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  float: left;
}
.ziduan-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60vw;
  margin: 0 auto;
  .checkbox {
    flex: 2;
    .el-checkbox {
      margin: 0;
      flex: 1;
      ::v-deep .el-checkbox__label {
        padding: 0;
      }
    }
  }
}
.ziduan-Header {
  display: flex;
  width: 60vw;
  margin: 0 auto;
  align-items: center;
}
.add-ziduanBox {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // width: 60vw;
  // margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  .add-ziduanBox-1 {
    flex: 1;
    ::v-deep .el-select {
      .el-tag:first-child {
        display: flex;
        align-items: center;
        .el-select__tags-text {
          display: inline-block;
          width: 110px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .add-ziduanBox-2 {
    flex: 2;
    .el-checkbox-group {
      display: flex;
      justify-content: space-around;
    }
  }
}
.add-ziduanBox-2 {
  flex: 2;
  .el-checkbox-group {
    display: flex;
    justify-content: space-around;
  }
}
.coverage-content {
  ::v-deep .el-scrollbar__view {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
}
::v-deep .myhide {
  .el-pager {
    .active::after {
      content: " " !important;
    }
  }
}
.confirm_status_dialog {
  .status_matching {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    .item {
      height: 70px;
      line-height: 70px;
      border-bottom: 1px solid rgba(229, 229, 229, 1);
      border-left: 1px solid rgba(229, 229, 229, 1);
      border-right: 1px solid rgba(229, 229, 229, 1);
      display: flex;
      & > * {
        flex: 1;
        text-align: center;
      }
      .el-form-item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0px;
        .el-select {
          width: 135px;
          ::v-deep .el-input {
            font-size: 16px;
          }
        }
      }
    }
    .title {
      height: 40px;
      line-height: 40px;
      border-top: 1px solid rgba(229, 229, 229, 1);
    }
  }
}
</style>
