// antx6 配置
import { Graph } from "@antv/x6";

export const workflow_config = {
  init_ball: {
    // 小灰球
    id: "grey_ball",
    shape: "circle",
    x: 40,
    width: 30,
    height: 30,
    attrs: {
      body: {
        fill: "rgba(193, 193, 193, 1)",
        stroke: "rgba(193, 193, 193, 1)",
      },
    },
  },
  init_edge: {
    // 小灰球的边
    shape: "grey-edge",
    source: "grey_ball",
    target: "",
  },
  // 连接桩
  ports: {
    groups: {
      top: {
        position: "top",
        attrs: {
          circle: {
            r: 4,
            magnet: true,
            stroke: "#5F95FF",
            strokeWidth: 1,
            fill: "#fff",
            style: {
              visibility: "hidden",
            },
          },
        },
      },
      right: {
        position: "right",
        attrs: {
          circle: {
            r: 4,
            magnet: true,
            stroke: "#5F95FF",
            strokeWidth: 1,
            fill: "#fff",
            style: {
              visibility: "hidden",
            },
          },
        },
      },
      bottom: {
        position: "bottom",
        attrs: {
          circle: {
            r: 4,
            magnet: true,
            stroke: "#5F95FF",
            strokeWidth: 1,
            fill: "#fff",
            style: {
              visibility: "hidden",
            },
          },
        },
      },
      left: {
        position: "left",
        attrs: {
          circle: {
            r: 4,
            magnet: true,
            stroke: "#5F95FF",
            strokeWidth: 1,
            fill: "#fff",
            style: {
              visibility: "hidden",
            },
          },
        },
      },
    },
    items: [
      {
        id: "port-top1",
        group: "top",
      },
      {
        id: "port-top2",
        group: "top",
      },
      {
        id: "port-top3",
        group: "top",
      },
      {
        id: "port-right1",
        group: "right",
      },
      {
        id: "port-right2",
        group: "right",
      },
      {
        id: "port-bottom1",
        group: "bottom",
      },
      {
        id: "port-bottom2",
        group: "bottom",
      },
      {
        id: "port-bottom3",
        group: "bottom",
      },
      {
        id: "port-left1",
        group: "left",
      },
      {
        id: "port-left2",
        group: "left",
      },
    ],
  },
};

export function registerWorkflowCell() {
  // 自定义边
  Graph.registerEdge(
    "grey-edge",
    {
      // 基类
      inherit: "edge",
      // 属性样式
      attrs: {
        line: {
          stroke: "#A6A6A6",
          strokeWidth: 2,
          targetMarker: {
            name: "classic",
            size: 8,
          },
        }
      },
      connector: {
        name: 'rounded',
        args: {
          radius: 10,
        },
      },
      router: {
        name: 'manhattan',
        args: {
          startDirections: ['bottom'],
          endDirections: ['top'],
          padding: {
            top: 35,
            bottom: 35,
            left: 25,
            right: 25,

          },
        },
      },
    },
    true
  );
  Graph.registerEdge(
    'custom-edge-label',
    {
      inherit: 'edge',
      defaultLabel: {
        markup: [
          {
            tagName: 'circle',
            selector: 'body',
          },
          {
            tagName: 'text',
            selector: 'label',
          },
        ],
        attrs: {
          label: {
            fill: '#fff',
            fontSize: 18,
            textAnchor: 'middle',
            textVerticalAnchor: 'middle',
            pointerEvents: 'none',
          },
          body: {
            ref: 'label',
            fill: '#174fa9',
            refR: 0.8,
            refCx: 0,
            refCy: 0,
            cursor: "pointer",
          },
        },
        position: {
          distance: 0.5,
        },
      },
      connector: {
        name: 'rounded',
        args: {
          radius: 10,
        },
      },
      router: {
        name: 'manhattan',
        args: {
          startDirections: ['bottom'],
          endDirections: ['top'],
          padding: {
            top: 35,
            bottom: 35,
            left: 25,
            right: 25,
          },
        },
      },
    },
    true,
  )
  //自定义节点
  Graph.registerNode(
    "todo-rect",
    {
      inherit: "rect", // 继承自 Shape.Rect
      width: 200, // 默认宽度
      height: 30, // 默认高度
      ports: { ...workflow_config.ports },
      attrs: {
        body: {
          fill: "#C1C1C1",
          stroke: "#C1C1C1",
          rx: 4,
          ry: 4,
        },
        label: {
          fill: "#000",
          fontSize: 16,
          fontWeight: "bold",
        },
      },
    },
    true
  );
  Graph.registerNode(
    "processing-rect",
    {
      inherit: "rect", // 继承自 Shape.Rect
      width: 200, // 默认宽度
      height: 30, // 默认高度
      ports: { ...workflow_config.ports },
      attrs: {
        body: {
          fill: "#fff",
          stroke: "#2A82E4",
          rx: 4,
          ry: 4,
        },
        label: {
          fill: "#2A82E4",
          fontSize: 16,
          fontWeight: "bold"
        },
      },
    },
    true
  );
  Graph.registerNode(
    "done-rect",
    {
      inherit: "rect", // 继承自 Shape.Rect
      width: 200, // 默认宽度
      height: 30, // 默认高度
      ports: { ...workflow_config.ports },
      attrs: {
        body: {
          fill: "#fff",
          stroke: "#A5D63F",
          rx: 4,
          ry: 4,
        },
        label: {
          fill: "#A5D63F",
          fontSize: 16,
          fontWeight: "bold"
        },
      },
    },
    true
  );
  Graph.registerNode(
    "allin-rect",
    {
      inherit: "rect", // 继承自 Shape.Rect
      width: 40, // 默认宽度
      height: 20, // 默认高度
      attrs: {
        body: {
          fill: "#30648f",
          stroke: "#30648f",
          rx: 2,
          ry: 2,
        },
        label: {
          text: "all",
          fill: "#fff",
          fontSize: 16,
          fontWeight: "bold",
        },
      },
    },
    true
  );
}



Graph.registerNode(
  "todo-rect-template",
  {
    inherit: "html", // 继承自 Shape.Rect
    // shape: "html",
    width: 200, // 默认宽度
    height: 30, // 默认高度
    ports: { ...workflow_config.ports },

    html(cell) {
      const con = document.createElement("div");
      con.innerHTML = `
            <div style="width:200px;
            font-size: 16px;
            height:30px;
            background:#C1C1C1;
            color:black;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius:4px">
            ${cell.getData().label}
          </div>
        `;

      return con;
    },
  },
  true
);

Graph.registerNode(
  "processing-rect-template",
  {
    inherit: "html", // 继承自 Shape.Rect
    // shape: "html",
    width: 200, // 默认宽度
    height: 30, // 默认高度
    ports: { ...workflow_config.ports },

    html(cell) {
      const con = document.createElement("div");
      con.innerHTML = `
            <div style="width:200px;
            font-size: 16px;
            height:30px;
            border:2px solid #2A82E4;
            color:#2A82E4;
            font-weight: bold;
            display: flex;
            border-radius: 4px;
            justify-content: center;
            align-items: center;
            border-radius:4px">
            ${cell.getData().label}
          </div>
        `;

      return con;
    },
  },
  true
);

Graph.registerNode(
  "done-rect-template",
  {
    inherit: "html", // 继承自 Shape.Rect
    // shape: "html",
    width: 200, // 默认宽度
    height: 30, // 默认高度
    ports: { ...workflow_config.ports },

    html(cell) {
      const con = document.createElement("div");
      con.innerHTML = `
            <div style="width:200px;
            font-size: 16px;
            height:30px;
            border:2px solid #A5D63F;
            color:#A5D63F;
            font-weight: bold;
            display: flex;
            border-radius: 4px;
            justify-content: center;
            align-items: center;
            border-radius:4px">
            ${cell.getData().label}
          </div>
        `;

      return con;
    },
  },
  true
);


Graph.registerNode(
  "allin-rect-template",
  {
    inherit: "html", // 继承自 Shape.Rect
    // shape: "html",
    width: 40, // 默认宽度
    height: 20, // 默认高度
    ports: { ...workflow_config.ports },

    html(cell) {
      const con = document.createElement("div");
      con.innerHTML = `
            <div style="width:40px;
            font-size: 16px;
            height:20px;
            background:#30648f;
            color:white;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius:2px">
            all
          </div>
        `;

      return con;
    },
  },
  true
);

