<template>
  <div class="workflow_template_list">
    <el-input
      ref="text_template"
      v-model="searchValue"
      v-popover:pop_template
      size="mini"
      :placeholder="$t('editFileType.workflow.placeholder')"
      :suffix-icon="popShow ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
      @click.native="get_template_list"
      style="cursor: poniter"
    ></el-input>
    <el-popover
      ref="pop_template"
      v-model="popShow"
      popper-class="pop_template"
      placement="bottom-start"
      trigger="click"
      :visible-arrow="false"
      @hide="pop_hide"
    >
      <div v-loading="loading" class="template">
        <div class="template_menu">
          <div class="title">
            {{ $t("editFileType.workflow.title2") }}
            <i
              class="iconfont icon-a-setting1"
              style="
                font-size: 16px;
                margin-left: 20px;
                cursor: pointer;
                color: rgb(127, 127, 128);
              "
              @click="show_edit_workflow = true"
            ></i>
          </div>
          <div class="menu_item">
            <el-scrollbar style="height: 100%; max-width: 200px">
              <div
                v-for="(item, index) in template_list_filter"
                :key="index"
                :class="{ active: selected === index }"
                @click="select_template(index, item.workflowTemplateId)"
                style="cursor: pointer"
              >
                <el-tooltip
                  effect="dark"
                  :content="item.name"
                  :disabled="show_tip"
                  placement="left"
                >
                  <p @mouseover="menu_mouseOver(`${item.name}-index`)">
                    <span :ref="`${item.name}-index`"> {{ item.name }}</span>
                  </p>
                </el-tooltip>
              </div>
            </el-scrollbar>
          </div>
        </div>
        <div class="template_preview">
          <div id="preview"></div>
          <!-- <img
            :src="png_pic"
            alt=""
            style="height: 260px"
            @mouseenter="onEnter"
            @mouseleave="onLeave"
            @mousemove="onMove"
          /> -->
          <div style="display: flex; position: relative">
            <div
              class="box"
              :style="minImgBoxStyle"
              @mouseleave="mouseLeave"
              @mouseenter="mouseEnter"
              @mousemove="mousemove($event)"
              style="border: none !important"
            >
              <!--原始照片-小照片-->
              <img
                :style="minImgStyle"
                fit="contain"
                ref="minImg"
                :src="png_pic"
              />
              <!--探测块-->
              <div v-show="show" class="areaMark" :style="areaMarkStyle"></div>
            </div>
            <div class="box maxImgBox" :style="maxImgBoxStyle" v-show="show">
              <!--放大后的照片-->
              <img :style="maxImgStyle" fit="contain" :src="png_pic" />
            </div>
          </div>
          <div class="footer">
            <el-button type="primary" size="mini" @click="set_template">{{
              $t("btn.confirmBtn")
            }}</el-button>
          </div>
        </div>
      </div>
    </el-popover>

    <el-dialog
      :visible.sync="show_edit_workflow"
      :close-on-click-modal="false"
      :show-close="true"
      width="25%"
      style="text-align: left"
      :title="$t('editFileType.workflow.dialog.tip14')"
    >
      <el-table
        :data="template_list"
        style="margin-bottom: 25px; max-height: 350px; overflow-y: scroll"
      >
        <el-table-column
          :key="randomKey"
          property="name"
          :label="$t('editFileType.workflow.dialog.tip15')"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span @dblclick="edit_workflow_name(scope.row)">
              <el-input
                v-show="show_input[scope.row.workflowTemplateId] === true"
                :ref="'enddateinput' + scope.row.workflowTemplateId"
                v-model="scope.row.name"
                @blur="blur_input(scope.row)"
                @keyup.enter.native="$event.target.blur"
              ></el-input>
              <span v-show="!show_input[scope.row.workflowTemplateId]">{{
                scope.row.name
              }}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column width="30" label=""></el-table-column>
        <el-table-column
          :label="$t('editFileType.workflow.dialog.tip16')"
          width="110"
        >
          <template slot-scope="scope">
            <span
              style="color: rgba(255, 87, 51, 1); cursor: pointer"
              @click="delete_workflow(scope.row)"
              >{{ $t("addMyColla.table.title10") }}</span
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {
  get_workflow_template,
  show_workflow_template,
  rename_workflow_template,
  delete_workflow_template,
} from "@/network/workflow/index.js";
import { Graph } from "@antv/x6";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      png_pic: "",
      scale: 2,
      show: false,
      finalMinIMGsrc: require("@/assets/img/nothing2.png"),
      finalMaxIMGsrc: require("@/assets/img/nothing2.png"),
      imgBoxWidth: 420,
      imgBoxHeight: 420,
      areaWidth: 210,
      areaHeight: 110,
      areaMarkStyle: {},
      minImgBoxStyle: {
        cursor: "move",
      },
      minImgStyle: {},
      maxImgBoxStyle: {},
      maxImgStyle: {
        position: "absolute",
      },
      loading: true,
      popShow: false,
      show_edit_workflow: false,
      show_input: {},
      randomKey: Math.random(),
      searchValue: "",
      template_list: [],
      template_list_filter: [],
      selected: 0,
      selected_template_id: "",
      first_template_id: "",
      show_tip: false,
      //画布
      graph: null,
      workflow_data: null,
      workflow_data_format: null,
      init_ball: {
        // 小灰球
        id: "grey_ball",
        shape: "circle",
        x: 40,
        width: 30,
        height: 30,
        attrs: {
          body: {
            fill: "rgba(193, 193, 193, 1)",
            stroke: "rgba(193, 193, 193, 1)",
          },
        },
      },
      init_edge: {
        // 小灰球的边
        shape: "grey-edge",
        source: "grey_ball",
        target: "",
      },
    };
  },
  computed: {},
  watch: {
    searchValue: {
      immediate: true, //在框的值还没变化时执行如下函数显示出所有的情况
      handler(val) {
        this.template_list_filter = this.template_list.filter((item) => {
          return item.name.indexOf(val) !== -1;
        });
        if (
          this.template_list_filter &&
          this.template_list_filter.length !== 0
        ) {
          this.get_template_preview(
            this.template_list_filter[0].workflowTemplateId
          );
        } else {
          this.workflow_data_format = {
            nodes: [],
            edges: [],
          };
          if (this.graph) {
            this.render_graph();
          }
        }
      },
    },
  },
  mounted() {
    this.init_graph();
    this.get_template_list();
  },

  methods: {
    getBase64ImageDimensions(base64Image) {
      return new Promise((resolve, reject) => {
        let img = new Image();

        img.onload = function () {
          let width = this.width;
          let height = this.height;
          resolve({ width, height, img });
        };

        img.onerror = function () {
          reject(new Error("Could not load image"));
        };

        img.src = base64Image;
      });
    },

    init() {
      this.$set(this.minImgStyle, "width", this.imgBoxWidth + "px");
      this.$set(this.minImgStyle, "height", this.imgBoxHeight + "px");

      this.$set(this.maxImgStyle, "width", this.imgBoxWidth + "px");
      this.$set(this.maxImgStyle, "height", this.imgBoxHeight + "px");

      this.$set(this.minImgBoxStyle, "width", this.imgBoxWidth + "px");
      this.$set(this.minImgBoxStyle, "height", this.imgBoxHeight + "px");

      this.$set(this.maxImgBoxStyle, "width", this.imgBoxWidth + "px");
      this.$set(this.maxImgBoxStyle, "height", this.imgBoxHeight + "px");

      this.$set(this.maxImgBoxStyle, "left", this.imgBoxWidth + "px");
      this.areaWidth = this.imgBoxWidth / this.scale;
      this.areaHeight = this.imgBoxHeight / this.scale;
      this.$set(this.areaMarkStyle, "width", this.areaWidth + "px");
      this.$set(this.areaMarkStyle, "height", this.areaHeight + "px");
      this.$set(this.maxImgStyle, "transform", "scale(" + this.scale + ")");
    },
    mouseEnter() {
      this.show = true;
    },
    mouseLeave() {
      this.show = false;
    },
    mousemove(e) {
      let documentScrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      let mouseClientX = e.clientX;
      let mouseClientY = e.clientY;
      let minImgPosition = this.$refs.minImg.getBoundingClientRect();
      let minImgX = minImgPosition.left;
      let minImgY = minImgPosition.top;
      let areaLeft = mouseClientX - minImgX - this.areaWidth / 2;
      let areaTop = mouseClientY - minImgY - this.areaHeight / 2;
      if (documentScrollTop > 0) {
        areaTop = documentScrollTop + areaTop;
      }
      let minLeft = 0;
      let maxLeft = this.imgBoxWidth - this.areaWidth;
      let minTop = 0;
      let maxTop = this.imgBoxHeight - this.areaHeight;
      if (areaLeft < minLeft) {
        areaLeft = minLeft;
      }
      if (areaLeft > maxLeft) {
        areaLeft = maxLeft;
      }
      if (areaTop < minTop) {
        areaTop = minTop;
      }
      if (areaTop > maxTop) {
        areaTop = maxTop;
      }
      this.$set(this.areaMarkStyle, "left", areaLeft + "px");
      this.$set(this.areaMarkStyle, "top", areaTop + "px");
      this.$set(
        this.maxImgStyle,
        "left",
        ((this.scale - 1) * this.imgBoxWidth) / 2 - areaLeft * this.scale + "px"
      );
      this.$set(
        this.maxImgStyle,
        "top",
        ((this.scale - 1) * this.imgBoxHeight) / 2 - areaTop * this.scale + "px"
      );
    },
    edit_workflow_name(workflow) {
      workflow.oldName = workflow.name;
      this.$set(this.show_input, workflow.workflowTemplateId, true);
      setTimeout(() => {
        //定时器是为了避免没有获取到dom的情况报错，所以象征性的给1毫秒让他缓冲
        this.$refs["enddateinput" + workflow.workflowTemplateId].focus();
        //el-input的autofocus失效，所以用这个方法。对应在template里的refs绑定值
      }, 1);
    },
    // 失去焦点处理
    blur_input(workflow) {
      rename_workflow_template(
        this.get_pid(),
        workflow.workflowTemplateId,
        workflow.name
      )
        .then((res) => {
          this.$message({
            message: this.$t("editFileType.workflow.dialog.tip17"),
            type: "success",
          });
        })
        .catch((e) => {
          this.$message.error(this.$t("editFileType.workflow.dialog.tip18"));
          workflow.name = workflow.oldName;
        });
      this.$set(this.show_input, workflow.workflowTemplateId, false);
    },
    // 删除工作流模板
    delete_workflow(workflow) {
      delete_workflow_template(this.get_pid(), workflow.workflowTemplateId)
        .then((res) => {
          this.$message({
            message: this.$t("editFileType.workflow.dialog.tip19"),
            type: "success",
          });
          this.get_template_list();
        })
        .catch((e) => {
          this.$message.error(this.$t("editFileType.workflow.dialog.tip20"));
        });
    },
    get_template_list() {
      get_workflow_template(this.get_pid()).then((res) => {
        this.template_list = res;
        this.template_list_filter = res;
        this.first_template_id = res[0].workflowTemplateId;
        this.get_template_preview(this.first_template_id);
      });
    },
    async get_template_preview(template_id) {
      await show_workflow_template(this.get_pid(), template_id).then((res) => {
        this.workflow_data = res; // 保存无小灰球的接口数据
        this.workflow_data_format = JSON.parse(JSON.stringify(res)); // copy一份，指向另外空间
        //加入allin节点
        this.workflow_data_format.nodes
          .filter((item) => {
            return item.data && item.data.allIn;
          })
          .forEach((ele) => {
            let allin_node = {
              shape: "allin-rect",
              x: ele.x + 240,
              y: ele.y,
              id: `${ele.id}-allin`,
            };
            let allin_edge = {
              shape: "grey-edge",
              source: allin_node.id,
              target: ele.id,
            };
            this.workflow_data_format.nodes.push(allin_node);
            this.workflow_data_format.edges.push(allin_edge);
          });
        // 加入小灰球
        let [init_target] = this.workflow_data_format.nodes.filter((item) => {
          return item.data && item.data.initialStatus == true;
        });
        this.init_edge.target = init_target.id;
        this.workflow_data_format.nodes.unshift(this.init_ball);
        this.workflow_data_format.edges.unshift(this.init_edge);
        this.render_graph();
      });
    },
    init_graph() {
      this.graph = new Graph({
        container: document.getElementById("preview"),
        width: "100%",
        height: 260,
        interacting: {
          nodeMovable: false,
        },
        connecting: {
          router: {
            name: "manhattan",
            args: {
              padding: 1,
            },
          },
        },
        panning: true,
      });
      this.graph.zoom(-0.78);
    },
    set_node_width(label) {
      // 动态设置新添加的节点宽度,若有汉字则倍数20
      let len = label.length;
      if (/[\u4E00-\u9FA5]+/g.test(this.new_status_cur_name)) {
        return len * 20;
      } else {
        return len * 15;
      }
    },
    render_graph() {
      this.workflow_data_format.nodes.forEach((item) => {
        if (item.label) {
          item.data.label = item.label;
        }
        if (
          item.shape == "processing-rect" ||
          item.shape == "todo-rect" ||
          item.shape == "done-rect"
        ) {
          item.label = "";
        }
        if (
          item.shape == "processing-rect" ||
          item.shape == "todo-rect" ||
          item.shape == "done-rect" ||
          item.shape == "allin-rect"
        ) {
          item.shape = item.shape + "-template";
        }
      });
      console.log(this.workflow_data_format);
      this.graph.fromJSON(this.workflow_data_format);
      this.graph.centerContent(); // 内容显示在画布中央
      this.graph.zoomTo(1);

      setTimeout(() => {
        this.graph.toPNG((dataUri) => {
          this.getBase64ImageDimensions(dataUri).then((dimensions) => {
            // 计算目标尺寸（包括padding）
            let targetWidth = 1000;
            let padding = 20;
            const targetSize = targetWidth + 2 * padding;

            // 创建一个canvas元素
            const canvas = document.createElement("canvas");
            canvas.width = targetSize;
            canvas.height = targetSize;

            // 获取2D渲染上下文
            const ctx = canvas.getContext("2d");

            // 绘制白色背景
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, targetSize, targetSize);

            // 计算等比例缩放后的图片尺寸
            const scale = Math.min(
              targetWidth / dimensions.width,
              targetWidth / dimensions.height
            );
            const scaledWidth = dimensions.width * scale;
            const scaledHeight = dimensions.height * scale;

            // 计算图片在canvas中的位置（居中）
            const x = (targetSize - scaledWidth) / 2;
            const y = (targetSize - scaledHeight) / 2;

            // 绘制图片到canvas中
            ctx.drawImage(dimensions.img, x, y, scaledWidth, scaledHeight);

            this.png_pic = canvas.toDataURL("image/png");
            this.init();
            this.loading = false;
          });
        });
      }, 100);
    },
    scaleImageSize(originalWidth, originalHeight) {
      const maxWidth = 420;
      const maxHeight = 420;

      let widthRatio = maxWidth / originalWidth;
      let heightRatio = maxHeight / originalHeight;

      let ratio = Math.min(widthRatio, heightRatio);
      let newWidth = Math.floor(originalWidth * ratio);
      let newHeight = Math.floor(originalHeight * ratio);

      return {
        width: newWidth,
        height: newHeight,
      };
    },
    select_template(index, template_id) {
      this.selected = index;
      this.get_template_preview(template_id);
    },
    pop_hide() {
      this.popShow = false;
      this.selected = 0;
      this.get_template_list();
    },
    set_template() {
      this.$emit("set_template", this.workflow_data);
      this.pop_hide();
    },
    menu_mouseOver(name) {
      const tag = this.$refs[name][0];
      const parentWidth = tag.parentNode.offsetWidth; // 获取元素父级可视宽度
      const contentWidth = tag.offsetWidth; // 获取元素可视宽度
      this.show_tip = contentWidth <= parentWidth;
    },
  },
};
</script>

<style scoped>
#preview {
  height: 0 !important;
}
</style>

<style lang="scss">
// el-popover样式不可以放在scoped中
.pop_template {
  padding: 0px;
  .template {
    display: flex;
    .template_menu {
      padding-bottom: 30px;
      // width: 140px; /*no*/
      background: rgba(229, 229, 229, 0.25);
      .title {
        font-size: 16px;
        margin: 25px 30px;
        color: #000;
      }
      .menu_item {
        display: flex;
        flex-direction: column;
        height: 250px; /*no*/
        .el-scrollbar__wrap {
          overflow-x: hidden;
        }
        p {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 0 20px;
          font-size: 14px;
          text-align: right;
          height: 32px; /*no*/
          line-height: 32px; /*no*/
          margin: 0;
          color: rgba(128, 128, 128, 1);
          &:hover {
            background-color: #ecf5ff;
            color: #66b1ff;
          }
        }
        .active {
          background: #fff;
        }
      }
    }
    .template_preview {
      width: 460px; /*no*/
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .footer {
        position: absolute;
        bottom: 30px;
        right: 30px;
      }
    }
  }
}
.workflow_template_list {
  .el-table {
    border: 0;
    th,
    tr,
    td {
      border: 0;
      background-color: #fff;
    }
    &::before {
      height: 0px;
    }
    &::after {
      width: 0px;
    }
    .el-table__fixed:before {
      height: 0;
    }
    .el-input__inner {
      height: 30px;
      line-height: 30px;
    }
  }
}
.box {
  border: 1px solid darkgray;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.areaMark {
  position: absolute;
  background: #80808042;
}
.maxImgBox {
  position: absolute;
  z-index: 999;
}
</style>
