<template>
  <div class="workflow_edit">
    <!-- 工具栏 -->
    <el-form ref="tools_form" :inline="true" class="workflow_form">
      <!-- 模板 -->
      <el-form-item v-if="isEdit">
        <workflow-template @set_template="set_template"></workflow-template>
      </el-form-item>
      <el-form-item v-if="isEdit">
        <el-select
          value=""
          size="mini"
          filterable
          :placeholder="$t('editFileType.workflow.template.tip7')"
          popper-class="select_own"
          @change="add_workflow_item"
        >
          <el-option key="add_new" class="status-add-option" value="add_new">
            <div
              class="status-add"
              style="
                width: 100%;
                display: flex;
                justify-content: center;
                height: 100%;
                align-items: center;
              "
              @click.stop="add_status_dialog(true)"
            >
              <i class="iconfont icon-add"></i>
            </div>
          </el-option>
          <el-option
            v-for="item in workflow_items"
            :key="item.statusId"
            :label="item.name"
            :value="item.statusId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="isEdit">
        <div class="save_new_template" @click="show_addtemplate_dialog">
          <i class="iconfont icon-save1"></i>
          {{ $t("editFileType.workflow.saveAsNewTemplateBtn") }}
        </div>
      </el-form-item>
      <el-form-item v-if="isEdit">
        <el-button
          type="primary"
          class="save_chart"
          size="mini"
          @click="save_workflow"
          >{{ $t("btn.saveBtn") }}
        </el-button>
      </el-form-item>
      <el-form-item style="flex: 1" class="workflow_form_edit">
        <el-button
          v-if="!isEdit"
          type="primary"
          class="save_chart"
          size="mini"
          icon="el-icon-edit-outline"
          @click="edit_workflow"
          >{{ $t("editFileType.workflow.template.tip8") }}
        </el-button>
        <el-button
          v-else
          class="save_chart"
          size="mini"
          icon="el-icon-edit-outline"
          @click="edit_workflow_quit"
          >{{ $t("editFileType.workflow.template.tip9") }}
        </el-button>
      </el-form-item>
    </el-form>
    <!-- 工作流 -->
    <div ref="workflow_edit" class="workflow_render">
      <!-- 工作流属性面板 -->
      <div v-if="all_in" class="tools">
        <el-card class="box-card">
          <el-checkbox-group v-model="checkList">
            <el-checkbox
              v-for="(item, index) in Object.keys(checked_list)"
              :key="index"
              :class="{ active_item: selected_node === item }"
              :label="item"
              @change="(checked) => add_allin_node(checked, item)"
              >{{ $t("editFileType.workflow.info") }}</el-checkbox
            >
          </el-checkbox-group>
        </el-card>
      </div>
      <!-- 工作流画布 -->
      <div id="container"></div>
    </div>
    <!-- 另存为模板弹窗 -->
    <el-dialog
      :title="$t('editFileType.workflow.saveAsNewTemplateBtn')"
      :visible="tempalte_dialog_visible"
      :close-on-click-modal="false"
      :show-close="true"
      width="25%"
      class="new_status_dialog"
      @close="show_addtemplate_dialog(false)"
    >
      <el-form
        ref="addTemplateForm"
        :model="template_form"
        label-position="left"
        label-width="100px"
      >
        <el-form-item
          prop="template_name"
          :rules="[
            {
              required: true,
              message: $t('editFileType.workflow.warn'),
              trigger: 'blur',
            },
          ]"
        >
          <template slot="label">
            <span>{{
              $t("editFileType.workflow.saveAsNewTemplateForm.name")
            }}</span>
          </template>
          <el-input v-model="template_form.template_name"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="plain" @click="show_addtemplate_dialog(false)">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button
          type="primary"
          @click="submitTemplateForm('addTemplateForm')"
          >{{ $t("btn.newBtn") }}</el-button
        >
      </div>
    </el-dialog>
    <!-- 工作流功能抽屉 -->
    <el-drawer
      :visible.sync="dialogWorkflowFn"
      custom-class="drawerWorkflowFn"
      @close="
        dialogWorkflowFn = false;
        activeIndex = ['conditions', 'auto-trig', 'post-processing'];
        workflowFn_trigger_typeListNow = [];
        workflowFn_trigger_typeListAll = [];
        workflowFn_trigger_typeListSelect = [];
        workflowFn_conditions_typeListNow = [];
        workflowFn_conditions_typeListAll = [];
        workflowFn_conditions_typeListSelect = [];
        autoTrig_value = '';
        conditions_value = '';
        conditions_appoint_field = '';
        conditions_appoint_value = '';
        conditions_appoint_users = [];
        conditions_appoint_users_label = [];
        conditions_common_field1 = '';
        conditions_common_field2 = '';
        conditions_common_field2_label = '';
        conditions_common_field1_label = '';
        processing_value = '';
        processing_Status_value = '';
        processing_Status_value_label = '';
        processing_appoint_field = '';
        processing_appoint_value = '';
        processing_appoint_field_label = '';
        processing_appoint_value_label = '';
        autoTrigStatus_value = [];
        conditions_Status_value = '';
        conditions_Empty_value = '';
        conditions_job_value = [];
      "
    >
      <div
        slot="title"
        class="drawerWorkflowFn-title"
        style="width: 100%; height: 100%"
      >
        {{
          `${$t(
            "editFileType.workflow.template.tip10"
          )} ${workflow_source_label} -> ${workflow_target_label}`
        }}
      </div>

      <div class="drawerWorkflowFn-content">
        <div class="auto-trig-contentAll">
          <el-collapse v-model="activeIndex">
            <el-collapse-item v-loading="isAutotrig_loading" name="auto-trig">
              <!-- 自动触发title -->
              <div slot="title" class="el-collapse-itemTitle">
                {{ $t("editFileType.workflow.template.tip11")
                }}<el-tooltip effect="dark" placement="bottom">
                  <div slot="content">
                    {{ $t("editFileType.workflow.template.tip12") }}
                  </div>
                  <i class="el-icon-question" style="font-size: 16px"></i>
                </el-tooltip>
              </div>
              <!-- 已有的触发器 -->
              <div class="auto-trig-main">
                <div
                  v-for="(item, index) in workflowFn_trigger_typeListNow"
                  :key="index"
                  class="auto-trig-content"
                >
                  <!-- 普通类型的触发器 -->
                  <div
                    v-if="
                      item.triggerType !== 'STATUS_BARRIER' &&
                      item.triggerType !== 'NODE_RELATION_STATUS_BARRIER'
                    "
                    class="auto-trig-content1"
                  >
                    <div class="auto-trig-contentMain">
                      {{ item.description }}
                    </div>
                    <div
                      class="deleteImgIcon"
                      @click="autoTrig_deleteCondition(item.triggerId, false)"
                    >
                      <img src="@/assets/img/narrow.png" alt="" />
                    </div>
                  </div>
                  <!-- 状态类型触发器 -->
                  <div
                    v-if="item.triggerType == 'STATUS_BARRIER'"
                    class="auto-trig-content2"
                  >
                    <div class="auto-trig-content2_1">
                      <div class="auto-trig-contentMain">
                        {{
                          `${$t(
                            "editFileType.workflow.template.tip13"
                          )}${autoTrigStatus_label.join(",")}${$t(
                            "editFileType.workflow.template.tip29"
                          )}`
                        }}
                      </div>
                      <div
                        class="deleteImgIcon"
                        @click="autoTrig_deleteCondition(item.triggerId, false)"
                      >
                        <img src="@/assets/img/narrow.png" alt="" />
                      </div>
                    </div>
                    <el-select
                      v-model="autoTrigStatus_value"
                      :placeholder="$t('editFileType.workflow.template.tip15')"
                      filterable
                      multiple
                      @change="select_on_autoTrigStatus(item.triggerId)"
                    >
                      <el-option
                        v-for="item in next_appoint_status"
                        :key="item.statusId"
                        :label="item.name"
                        :value="item.statusId"
                      >
                      </el-option>
                    </el-select>
                  </div>

                  <!-- 关联类型的触发器 -->
                  <div
                    v-if="item.triggerType == 'NODE_RELATION_STATUS_BARRIER'"
                    class="auto-trig-content2"
                  >
                    <div class="auto-trig-content2_1">
                      <div class="auto-trig-contentMain">
                        {{ `${$t("editFileType.workflow.template.tip57")}` }}
                      </div>
                      <div
                        class="deleteImgIcon"
                        @click="autoTrig_deleteCondition(item.triggerId, false)"
                      >
                        <img src="@/assets/img/narrow.png" alt="" />
                      </div>
                    </div>
                    <div style="display: flex">
                      <el-select
                        style="flex: 1"
                        v-model="back_file"
                        :placeholder="
                          $t('editFileType.workflow.template.tip56')
                        "
                        filterable
                        @change="select_autoTrigFileType(true, item)"
                      >
                        <el-option
                          v-for="item2 in next_appoint_filetype"
                          :key="item2.fileTypeId"
                          :label="item2.name"
                          :value="item2.fileTypeId"
                        >
                        </el-option>
                      </el-select>

                      <el-select
                        style="flex: 1"
                        v-model="back_status"
                        :placeholder="
                          $t('editFileType.workflow.template.tip15')
                        "
                        filterable
                        multiple
                        @change="select_autoTrigStatus(true, item)"
                      >
                        <el-option
                          v-for="item1 in next_appoint_status"
                          :key="item1.statusId"
                          :label="item1.name"
                          :value="item1.statusId"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 这部分的代码是添加触发器的 -->
              <div
                v-if="workflowFn_trigger_typeListSelect.length > 0"
                class="dialogWorkflowFn-display-footer"
              >
                <div
                  class="dialogWorkflowFn-display-footer-select"
                  style="flex-direction: column"
                >
                  <!-- 选择触发器 -->
                  <el-select
                    style="flex: 1"
                    v-model="autoTrig_value"
                    :placeholder="$t('editFileType.workflow.template.tip16')"
                    filterable
                    @change="select_autoTrig"
                  >
                    <el-option
                      v-for="item in workflowFn_trigger_typeListSelect"
                      :key="item.triggerType"
                      :label="item.description"
                      :value="item.triggerType"
                    >
                    </el-option>
                  </el-select>
                  <!-- 当选择了状态触发器 -->
                  <el-select
                    style="flex: 1"
                    v-if="autoTrig_value == 'STATUS_BARRIER'"
                    v-model="autoTrigStatus_value"
                    :placeholder="$t('editFileType.workflow.template.tip15')"
                    filterable
                    multiple
                    @change="select_autoTrigStatus()"
                  >
                    <el-option
                      v-for="item in next_appoint_status"
                      :key="item.statusId"
                      :label="item.name"
                      :value="item.statusId"
                    >
                    </el-option>
                  </el-select>
                  <!-- 当选择了关联触发器 -->
                  <div style="display: flex; margin-top: 10px">
                    <el-select
                      style="flex: 1"
                      v-if="autoTrig_value == 'NODE_RELATION_STATUS_BARRIER'"
                      v-model="back_file"
                      :placeholder="$t('editFileType.workflow.template.tip56')"
                      filterable
                      @change="select_autoTrigFileType(false)"
                    >
                      <el-option
                        v-for="item in next_appoint_filetype"
                        :key="item.fileTypeId"
                        :label="item.name"
                        :value="item.fileTypeId"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      style="flex: 1"
                      v-if="autoTrig_value == 'NODE_RELATION_STATUS_BARRIER'"
                      v-model="back_status"
                      :placeholder="$t('editFileType.workflow.template.tip15')"
                      filterable
                      multiple
                      @change="select_autoTrigStatus('zdh')"
                    >
                      <el-option
                        v-for="item in next_appoint_status"
                        :key="item.statusId"
                        :label="item.name"
                        :value="item.statusId"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div v-if="autoTrig_value" class="buttonContent">
                  <el-button
                    icon="el-icon-plus"
                    @click="autoTrig_addCondition()"
                    >{{ $t("editFileType.workflow.template.tip20") }}</el-button
                  >
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div class="conditions-contentAll">
          <el-collapse v-model="activeIndex">
            <el-collapse-item
              v-loading="isConditions_loading"
              name="conditions"
            >
              <div slot="title" class="el-collapse-itemTitle">
                {{ $t("editFileType.workflow.template.tip21") }}
              </div>
              <div class="change-conditions">
                <el-select
                  v-model="judgeLogic"
                  filterable
                  @change="change_judgeLogic"
                >
                  <el-option
                    v-for="item in conditions_change_option"
                    :key="item.judgeLogic"
                    :label="item.label"
                    :value="item.judgeLogic"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="auto-trig-main">
                <div
                  v-for="(item, index) in workflowFn_conditions_typeListNow"
                  :key="index"
                  class="auto-trig-content"
                >
                  <!-- 无需传参数条件 -->
                  <div
                    v-if="
                      item.validatorType == 'ASSIGNEE_EQUALS' ||
                      item.validatorType == 'CREATED_BY_EQUALS' ||
                      item.validatorType == 'NODE_REVIEW_APPROVED' ||
                      item.validatorType == 'REVIEW_BARRIER'
                    "
                    class="auto-trig-content1"
                  >
                    <div class="auto-trig-contentMain">
                      {{ item.description }}
                    </div>
                    <div
                      class="deleteImgIcon"
                      @click="conditions_deleteCondition(item.validatorId)"
                    >
                      <img src="@/assets/img/narrow.png" alt="" />
                    </div>
                  </div>
                  <!-- 指定状态条件项 -->
                  <div
                    v-else-if="item.validatorType == 'STATUS_BARRIER'"
                    class="auto-trig-content2"
                  >
                    <div class="auto-trig-content2_1">
                      <div class="auto-trig-contentMain">
                        {{
                          `${$t("editFileType.workflow.template.tip13")}${
                            item.statusName
                          }${$t("editFileType.workflow.template.tip23")}`
                        }}
                      </div>
                      <div
                        class="deleteImgIcon"
                        @click="
                          conditions_deleteCondition(
                            item.validatorId,
                            item.validatorType
                          )
                        "
                      >
                        <img src="@/assets/img/narrow.png" alt="" />
                      </div>
                    </div>
                  </div>
                  <!-- 字段 = 值 -->
                  <div
                    v-else-if="
                      item.validatorType == 'FIELD_EQUALS' &&
                      item.usersNames.length > 0
                    "
                    class="auto-trig-content2"
                  >
                    <div class="auto-trig-content2_1">
                      <div class="auto-trig-contentMain">
                        {{
                          `${$t("editFileType.workflow.template.tip22")}${
                            item.fieldName
                          }=${item.usersNames}${$t(
                            "editFileType.workflow.template.tip23"
                          )}`
                        }}
                      </div>
                      <div
                        class="deleteImgIcon"
                        @click="
                          conditions_deleteCondition(
                            item.validatorId,
                            item.validatorType
                          )
                        "
                      >
                        <img src="@/assets/img/narrow.png" alt="" />
                      </div>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      item.validatorType == 'FIELD_EQUALS' && item.value
                    "
                    class="auto-trig-content2"
                  >
                    <div class="auto-trig-content2_1">
                      <div class="auto-trig-contentMain">
                        {{
                          `${$t("editFileType.workflow.template.tip22")}${
                            item.fieldName
                          }=${item.value}${$t(
                            "editFileType.workflow.template.tip23"
                          )}`
                        }}
                      </div>
                      <div
                        class="deleteImgIcon"
                        @click="
                          conditions_deleteCondition(
                            item.validatorId,
                            item.validatorType
                          )
                        "
                      >
                        <img src="@/assets/img/narrow.png" alt="" />
                      </div>
                    </div>
                  </div>
                  <!-- 指定几个用户 -->
                  <div
                    v-else-if="item.validatorType == 'APPOINT_USERS'"
                    class="auto-trig-content2 users_select"
                  >
                    <div class="auto-trig-content2_1">
                      <div class="auto-trig-contentMain">
                        {{
                          `${$t("editFileType.workflow.template.tip24")}${
                            item.usersName
                          }${$t("editFileType.workflow.template.tip25")}
`
                        }}
                      </div>
                      <div
                        class="deleteImgIcon"
                        @click="
                          conditions_deleteCondition(
                            item.validatorId,
                            item.validatorType
                          )
                        "
                      >
                        <img src="@/assets/img/narrow.png" alt="" />
                      </div>
                    </div>
                  </div>
                  <!-- 字段相同 -->
                  <div
                    v-else-if="item.validatorType == 'TWO_FIELD_EQUALS'"
                    class="auto-trig-content1"
                  >
                    <div class="auto-trig-contentMain">
                      {{
                        `${$t("editFileType.workflow.template.tip26")}${
                          item.field1Name
                        }${$t("editFileType.workflow.template.tip27")}${
                          item.field2Name
                        }${$t("editFileType.workflow.template.tip28")}`
                      }}
                    </div>
                    <div
                      class="deleteImgIcon"
                      @click="conditions_deleteCondition(item.validatorId)"
                    >
                      <img src="@/assets/img/narrow.png" alt="" />
                    </div>
                  </div>
                  <!-- 字段不为空 -->
                  <div
                    v-else-if="item.validatorType == 'FIELD_NOT_EMPTY'"
                    class="auto-trig-content2"
                  >
                    <div class="auto-trig-content2_1">
                      <div class="auto-trig-contentMain">
                        {{
                          `${$t("editFileType.workflow.template.tip30")}${
                            item.emptyName
                          }${$t("editFileType.workflow.template.tip55")}`
                        }}
                      </div>
                      <div
                        class="deleteImgIcon"
                        @click="
                          conditions_deleteCondition(
                            item.validatorId,
                            item.validatorType
                          )
                        "
                      >
                        <img src="@/assets/img/narrow.png" alt="" />
                      </div>
                    </div>
                  </div>
                  <!-- 指定用户职位 -->
                  <div
                    v-else-if="item.validatorType == 'USER_JOB_EQUALS'"
                    class="auto-trig-content2"
                  >
                    <div class="auto-trig-content2_1">
                      <div class="auto-trig-contentMain">
                        {{
                          `${$t("editFileType.workflow.template.tip31")}${
                            item.jobIdNames
                          }${$t("editFileType.workflow.template.tip54")}`
                        }}
                      </div>
                      <div
                        class="deleteImgIcon"
                        @click="
                          conditions_deleteCondition(
                            item.validatorId,
                            item.validatorType
                          )
                        "
                      >
                        <img src="@/assets/img/narrow.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="dialogWorkflowFn-display-footer">
                <div
                  class="dialogWorkflowFn-display-footer-select dialogWorkflowFn-select"
                >
                  <!-- 选择验证条件下拉框 -->
                  <div>
                    <el-select
                      v-model="conditions_value"
                      :placeholder="$t('editFileType.workflow.template.tip16')"
                      filterable
                      style="width: 100%"
                      @change="select_conditions"
                    >
                      <el-option
                        v-for="item in workflowFn_conditions_typeListSelect"
                        :key="item.validatorType"
                        :label="item.description"
                        :value="item.validatorType"
                      >
                      </el-option>
                    </el-select>
                  </div>

                  <div style="padding-right: 10px; padding-top: 19px">
                    <!-- 指定状态下拉框 -->
                    <div
                      v-if="conditions_value == 'STATUS_BARRIER'"
                      style="display: flex; align-items: center"
                    >
                      <div
                        style="
                          color: rgba(128, 128, 128, 1);
                          font-size: 14px;
                          font-weight: 400;
                          text-align: left;
                          flex: 1;
                          min-width: 70px;
                        "
                      >
                        {{ $t("editFileType.workflow.template.tip14") }}*
                      </div>
                      <el-select
                        v-model="conditions_Status_value"
                        :placeholder="
                          $t('editFileType.workflow.template.tip15')
                        "
                        filterable
                        style="flex: 7"
                        @change="select_conditionsStatus"
                      >
                        <el-option
                          v-for="item in next_appoint_status"
                          :key="item.statusId"
                          :label="item.name"
                          :value="item.statusId"
                        >
                        </el-option>
                      </el-select>
                    </div>

                    <!-- 字段 = 值 -->
                    <div
                      v-else-if="conditions_value == 'FIELD_EQUALS'"
                      class="conditions_field"
                      style="display: flex; align-items: center; width: 70%"
                    >
                      <div class="">
                        {{ $t("editFileType.workflow.template.tip17")
                        }}<span style="color: red">*</span>
                      </div>
                      <div>
                        <el-select
                          v-model="conditions_appoint_field"
                          :placeholder="
                            $t('editFileType.workflow.template.tip18')
                          "
                          filterable
                          @change="select_conditions_appoint_field"
                        >
                          <el-option
                            v-for="item in workflow_field_arr"
                            :key="item.fieldId"
                            :label="item.name"
                            :value="item.fieldId"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div v-if="conditions_appoint_field">=</div>
                      <div v-if="conditions_appoint_field">
                        {{ $t("editFileType.workflow.template.tip32")
                        }}<span style="color: red">*</span>
                      </div>
                      <div v-if="conditions_appoint_field">
                        <!-- 数字 -->
                        <el-input
                          v-if="conditions_appoint_field2Type == 'NUMBER'"
                          v-model="conditions_appoint_value"
                          oninput="value=value.replace(/[^\-?\d.]/g,'')"
                          :placeholder="
                            $t('editFileType.workflow.template.tip33')
                          "
                        ></el-input>
                        <!-- 多用户 -->
                        <el-select
                          v-else-if="
                            conditions_appoint_field2Type == 'MULTI_USER_SELECT'
                          "
                          v-model="conditions_appoint_value"
                          :placeholder="
                            $t('editFileType.workflow.template.tip19')
                          "
                          multiple
                          filterable
                        >
                          <el-option
                            v-for="item in userList"
                            :key="item.accountId"
                            :label="item.nickname"
                            :value="item.accountId"
                          >
                          </el-option>
                        </el-select>
                        <!-- 多行文本 -->
                        <el-input
                          v-else-if="
                            conditions_appoint_field2Type == 'TEXTAREA'
                          "
                          v-model="conditions_appoint_value"
                          :placeholder="
                            $t('editFileType.workflow.template.tip35')
                          "
                          type="textarea"
                        ></el-input>
                        <!-- 单行文本 -->
                        <el-input
                          v-else-if="conditions_appoint_field2Type == 'TEXT'"
                          v-model="conditions_appoint_value"
                          :placeholder="
                            $t('editFileType.workflow.template.tip34')
                          "
                        ></el-input>
                        <!-- 链接 -->
                        <el-input
                          v-else-if="conditions_appoint_field2Type == 'LINK'"
                          v-model="conditions_appoint_value"
                          :placeholder="
                            $t('editFileType.workflow.template.tip37')
                          "
                        ></el-input>
                        <!-- 单用户 -->
                        <el-select
                          v-else-if="
                            conditions_appoint_field2Type ==
                            'SINGLE_USER_SELECT'
                          "
                          v-model="conditions_appoint_value"
                          :placeholder="
                            $t('editFileType.workflow.template.tip19')
                          "
                          filterable
                        >
                          <el-option
                            v-for="item in userList"
                            :key="item.accountId"
                            :label="item.nickname"
                            :value="item.accountId"
                          >
                          </el-option>
                        </el-select>
                        <!-- 日期选择框 -->
                        <el-date-picker
                          v-else-if="conditions_appoint_field2Type == 'DATE'"
                          v-model="conditions_appoint_value"
                          type="date"
                          :placeholder="
                            $t('editFileType.workflow.template.tip40')
                          "
                          value-format="yyyy-MM-dd"
                        >
                        </el-date-picker>
                        <!-- 标签 -->
                        <el-select
                          v-else-if="conditions_appoint_field2Type == 'LABEL'"
                          v-model="conditions_appoint_value"
                          :placeholder="
                            $t('editFileType.workflow.template.tip41')
                          "
                          multiple
                          allow-create
                          filterable
                          clearable
                        >
                          <el-option
                            v-for="item in []"
                            :key="item.accountId"
                            :label="item.nickname"
                            :value="item.accountId"
                          >
                          </el-option>
                        </el-select>
                        <!-- 单选项 -->
                        <el-select
                          v-else-if="conditions_appoint_field2Type == 'SELECT'"
                          v-model="conditions_appoint_value"
                          :placeholder="
                            $t('editFileType.workflow.template.tip43')
                          "
                          filterable
                          clearable
                        >
                          <el-option
                            v-for="item in field_extraData"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                        <!-- 多选项 -->
                        <el-select
                          v-else-if="
                            conditions_appoint_field2Type == 'SELECT_MULTI'
                          "
                          v-model="conditions_appoint_value"
                          :placeholder="
                            $t('editFileType.workflow.template.tip45')
                          "
                          multiple
                          filterable
                          clearable
                        >
                          <el-option
                            v-for="item in field_extraData"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>

                    <!-- 指定几个用户 -->
                    <div
                      v-else-if="conditions_value == 'APPOINT_USERS'"
                      class="auto-trig-content2"
                      style="display: flex; align-items: center"
                    >
                      <div
                        style="
                          color: rgba(128, 128, 128, 1);
                          font-size: 14px;
                          font-weight: 400;
                          text-align: left;
                          flex: 1;
                          min-width: 70px;
                        "
                      >
                        {{ $t("editFileType.workflow.template.tip47") }}*
                      </div>
                      <el-select
                        v-model="conditions_appoint_users"
                        :placeholder="
                          $t('editFileType.workflow.template.tip19')
                        "
                        style="flex: 7"
                        multiple
                        filterable
                        @change="select_users"
                      >
                        <el-option
                          v-for="item in userList"
                          :key="item.accountId"
                          :label="item.nickname"
                          :value="item.accountId"
                        >
                        </el-option>
                      </el-select>
                    </div>

                    <!-- 两字段相同 -->
                    <div
                      v-else-if="conditions_value == 'TWO_FIELD_EQUALS'"
                      class="conditions_field conditions_common_field"
                      style="display: flex; align-items: center; width: 80%"
                    >
                      <div class="">
                        {{ $t("editFileType.workflow.template.tip48")
                        }}<span style="color: red">*</span>
                      </div>
                      <div>
                        <el-select
                          v-model="conditions_common_field1"
                          :placeholder="
                            $t('editFileType.workflow.template.tip49')
                          "
                          filterable
                          @change="conditions_common_field1Change"
                        >
                          <el-option
                            v-for="item in workflow_field_arr"
                            :key="item.fieldId"
                            :label="item.name"
                            :value="item.fieldId"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div>=</div>
                      <div v-if="conditions_common_field1">
                        {{ $t("editFileType.workflow.template.tip50")
                        }}<span style="color: red">*</span>
                      </div>
                      <div v-if="conditions_common_field1">
                        <el-select
                          v-model="conditions_common_field2"
                          :placeholder="
                            $t('editFileType.workflow.template.tip51')
                          "
                          filterable
                        >
                          <el-option
                            v-for="item in conditions_common_field2List"
                            :key="item.fieldId"
                            :label="item.name"
                            :value="item.fieldId"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>

                    <!-- 字段不为空 -->
                    <div
                      v-else-if="conditions_value == 'FIELD_NOT_EMPTY'"
                      style="display: flex; align-items: center"
                    >
                      <div
                        style="
                          color: rgba(128, 128, 128, 1);
                          font-size: 14px;
                          font-weight: 400;
                          text-align: left;
                          flex: 1;
                          min-width: 70px;
                        "
                      >
                        {{ $t("editFileType.workflow.template.tip52") }}*
                      </div>
                      <el-select
                        v-model="conditions_Empty_value"
                        :placeholder="
                          $t('editFileType.workflow.template.tip18')
                        "
                        filterable
                        style="flex: 7"
                        @change="select_conditionsEmpty"
                      >
                        <el-option
                          v-for="item in [
                            ...workflow_field_arr,
                            ...NOT_EMPTY_role_arr,
                          ]"
                          :key="item.fieldId"
                          :label="item.name"
                          :value="item.fieldId"
                        >
                        </el-option>
                      </el-select>
                    </div>

                    <!-- 指定用户职位 -->
                    <div
                      v-else-if="conditions_value == 'USER_JOB_EQUALS'"
                      style="display: flex; align-items: center"
                    >
                      <div
                        style="
                          color: rgba(128, 128, 128, 1);
                          font-size: 14px;
                          font-weight: 400;
                          text-align: left;
                          flex: 1;
                          min-width: 70px;
                        "
                      >
                        {{ $t("editFileType.workflow.template.tip53") }}*
                      </div>
                      <el-select
                        v-model="conditions_job_value"
                        :placeholder="$t('editFileType.workflow.dialog.tip1')"
                        multiple
                        filterable
                        style="flex: 7"
                        @change="select_conditionsJob"
                      >
                        <el-option
                          v-for="item in jobAuthorities"
                          :key="item.jobId"
                          :label="item.name"
                          :value="item.jobId"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
                <div v-if="conditions_value" class="buttonContent">
                  <el-button
                    icon="el-icon-plus
"
                    @click="conditions_addCondition"
                    >{{ $t("editFileType.workflow.template.tip20") }}</el-button
                  >
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div class="post-processing-contentAll">
          <el-collapse v-model="activeIndex">
            <el-collapse-item
              v-loading="isProcessing_loading"
              name="post-processing"
            >
              <div slot="title" class="el-collapse-itemTitle">
                {{ $t("editFileType.workflow.dialog.tip2") }}
              </div>
              <div class="auto-trig-main">
                <div
                  v-for="(item, index) in workflowFn_processing_typeListNow"
                  :key="index"
                  class="auto-trig-content"
                >
                  <!-- 无需传参数条件 -->
                  <div
                    v-if="
                      item.postHandlerType == 'ASSIGNEE_MODIFY_TO_CREATED_BY' ||
                      item.postHandlerType == 'ASSIGNEE_MODIFY_TO_CURRENT_USER'
                    "
                    class="auto-trig-content1"
                  >
                    <div class="auto-trig-contentMain">
                      {{ item.description }}
                    </div>
                    <div
                      class="deleteImgIcon"
                      @click="processing_deleteCondition(item.postHandlerId)"
                    >
                      <img src="@/assets/img/narrow.png" alt="" />
                    </div>
                  </div>
                  <!-- 指定状态条件项 -->
                  <div
                    v-else-if="item.postHandlerType == 'CHILD_STATUS_MODIFY'"
                    class="auto-trig-content2"
                  >
                    <div class="auto-trig-content2_1">
                      <div class="auto-trig-contentMain">
                        {{
                          `${$t(
                            "editFileType.workflow.dialog.tip3"
                          )}${processing_Status_value_label}`
                        }}
                      </div>
                      <div
                        class="deleteImgIcon"
                        @click="
                          processing_deleteCondition(
                            item.postHandlerId,
                            item.validatorType
                          )
                        "
                      >
                        <img src="@/assets/img/narrow.png" alt="" />
                      </div>
                    </div>

                    <el-select
                      v-model="processing_Status_value"
                      :placeholder="$t('editFileType.workflow.template.tip15')"
                      filterable
                      @change="
                        select_on_processingStatus(
                          item.postHandlerId,
                          'add_select_status'
                        )
                      "
                    >
                      <el-option
                        v-for="item in next_appoint_status"
                        :key="item.statusId"
                        :label="item.name"
                        :value="item.statusId"
                      >
                      </el-option>
                    </el-select>
                  </div>

                  <!-- 指定状态条件项 -->
                  <div
                    v-else-if="
                      item.postHandlerType ==
                      'ASSIGNEE_MODIFY_TO_SPECIFIED_USER'
                    "
                    class="auto-trig-content2"
                  >
                    <div class="auto-trig-content2_1">
                      <div class="auto-trig-contentMain">
                        {{
                          `${$t(
                            "editFileType.workflow.dialog.tip33"
                          )} ${processing_Assignee_value_label}`
                        }}
                      </div>
                      <div
                        class="deleteImgIcon"
                        @click="
                          processing_deleteCondition(
                            item.postHandlerId,
                            item.validatorType
                          )
                        "
                      >
                        <img src="@/assets/img/narrow.png" alt="" />
                      </div>
                    </div>

                    <el-select
                      v-model="processing_Assignee_value"
                      :placeholder="$t('editFileType.workflow.template.tip15')"
                      filterable
                      style="flex: 7"
                      @change="
                        select_on_processingAssignee(
                          item.postHandlerId,
                          'add_select_assignee'
                        )
                      "
                    >
                      <el-option
                        v-for="opt in project_user"
                        v-show="
                          !opt.deleted &&
                          opt.projectNames.includes(project_name)
                        "
                        :key="opt.accountId"
                        :label="opt.nickname"
                        :value="opt.accountId"
                        filterable
                        clearable
                        popper-class="user_select"
                      >
                        <div class="user_select">
                          <personal-avatar
                            class="avatar"
                            :avatar="opt.avatar"
                            :colorid="opt.accountId"
                            :nickname="opt.nickname"
                            :size="20"
                          >
                          </personal-avatar>
                          <span class="select_item">{{ opt.nickname }}</span>
                        </div>
                      </el-option>
                    </el-select>
                  </div>
                  <!-- 字段 = 值 -->
                  <div
                    v-else-if="item.postHandlerType == 'FIELD_MODIFY'"
                    class="auto-trig-content2"
                  >
                    <div class="auto-trig-content2_1">
                      <div class="auto-trig-contentMain">
                        {{
                          `${$t("editFileType.workflow.dialog.tip4")}${
                            item.fieldName
                          }${$t("editFileType.workflow.dialog.tip5")}${
                            item.value
                          }`
                        }}
                      </div>
                      <div
                        class="deleteImgIcon"
                        @click="
                          processing_deleteCondition(
                            item.postHandlerId,
                            item.validatorType
                          )
                        "
                      >
                        <img src="@/assets/img/narrow.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="dialogWorkflowFn-display-footer">
                <div
                  class="dialogWorkflowFn-display-footer-select dialogWorkflowFn-select"
                >
                  <!-- 选择验证条件下拉框 -->
                  <div>
                    <el-select
                      v-model="processing_value"
                      :placeholder="$t('editFileType.workflow.template.tip16')"
                      filterable
                      style="width: 100%"
                      @change="select_processing"
                    >
                      <el-option
                        v-for="item in workflowFn_processing_typeListSelect"
                        :key="item.postHandlerType"
                        :label="item.description"
                        :value="item.postHandlerType"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div style="padding-right: 10px; padding-top: 19px">
                    <!-- 指定状态下拉框 -->
                    <div
                      v-if="processing_value == 'CHILD_STATUS_MODIFY'"
                      style="display: flex; align-items: center"
                    >
                      <div
                        style="
                          color: rgba(128, 128, 128, 1);
                          font-size: 14px;
                          font-weight: 400;
                          text-align: left;
                          flex: 1;
                          min-width: 70px;
                        "
                      >
                        {{ $t("editFileType.workflow.template.tip14") }}*
                      </div>
                      <el-select
                        v-model="processing_Status_value"
                        :placeholder="
                          $t('editFileType.workflow.template.tip15')
                        "
                        filterable
                        style="flex: 7"
                        @change="select_processingStatus"
                      >
                        <el-option
                          v-for="item in next_appoint_status"
                          :key="item.statusId"
                          :label="item.name"
                          :value="item.statusId"
                        >
                        </el-option>
                      </el-select>
                    </div>
                    <!-- 指定状态下拉框 -->
                    <div
                      v-else-if="
                        processing_value == 'ASSIGNEE_MODIFY_TO_SPECIFIED_USER'
                      "
                      style="display: flex; align-items: center"
                    >
                      <div
                        style="
                          color: rgba(128, 128, 128, 1);
                          font-size: 14px;
                          font-weight: 400;
                          text-align: left;
                          flex: 1;
                          min-width: 70px;
                        "
                      >
                        {{ $t("editFileType.msg") }}*
                      </div>
                      <el-select
                        v-model="processing_Assignee_value"
                        :placeholder="
                          $t('editFileType.workflow.template.tip15')
                        "
                        filterable
                        style="flex: 7"
                      >
                        <el-option
                          v-for="opt in project_user"
                          v-show="
                            !opt.deleted &&
                            opt.projectNames.includes(project_name)
                          "
                          :key="opt.accountId"
                          :label="opt.nickname"
                          :value="opt.accountId"
                          filterable
                          clearable
                          popper-class="user_select"
                          :popper-append-to-body="true"
                        >
                          <div class="user_select">
                            <personal-avatar
                              class="avatar"
                              :avatar="opt.avatar"
                              :colorid="opt.accountId"
                              :nickname="opt.nickname"
                              :size="20"
                            >
                            </personal-avatar>
                            <span class="select_item">{{ opt.nickname }}</span>
                          </div>
                        </el-option>
                      </el-select>
                    </div>
                    <!-- 字段 = 值 -->
                    <div
                      v-else-if="processing_value == 'FIELD_MODIFY'"
                      class="conditions_field"
                      style="display: flex; align-items: center; width: 70%"
                    >
                      <div class="">
                        {{ $t("editFileType.workflow.template.tip17")
                        }}<span style="color: red">*</span>
                      </div>
                      <div>
                        <el-select
                          v-model="processing_appoint_field"
                          :placeholder="
                            $t('editFileType.workflow.template.tip18')
                          "
                          filterable
                          @change="select_processing_appoint_field"
                        >
                          <el-option
                            v-for="item in workflow_field_arr"
                            :key="item.fieldId"
                            :label="item.name"
                            :value="item.fieldId"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div v-if="processing_appoint_field">=</div>
                      <div v-if="processing_appoint_field">
                        {{ $t("editFileType.workflow.template.tip32")
                        }}<span style="color: red">*</span>
                      </div>
                      <div v-if="processing_appoint_field">
                        <!-- 数字 -->
                        <el-input
                          v-if="processing_appoint_field2Type == 'NUMBER'"
                          v-model="processing_appoint_value"
                          oninput="value=value.replace(/[^\-?\d.]/g,'')"
                          :placeholder="
                            $t('editFileType.workflow.template.tip33')
                          "
                        ></el-input>
                        <!-- 多用户 -->
                        <el-select
                          v-else-if="
                            processing_appoint_field2Type == 'MULTI_USER_SELECT'
                          "
                          v-model="processing_appoint_value"
                          :placeholder="
                            $t('editFileType.workflow.template.tip19')
                          "
                          multiple
                          filterable
                        >
                          <el-option
                            v-for="item in userList"
                            :key="item.accountId"
                            :label="item.nickname"
                            :value="item.accountId"
                          >
                          </el-option>
                        </el-select>
                        <!-- 多行文本 -->
                        <el-input
                          v-else-if="
                            processing_appoint_field2Type == 'TEXTAREA'
                          "
                          v-model="processing_appoint_value"
                          :placeholder="
                            $t('editFileType.workflow.template.tip34')
                          "
                          type="textarea"
                        ></el-input>
                        <!-- 单行文本 -->
                        <el-input
                          v-else-if="processing_appoint_field2Type == 'TEXT'"
                          v-model="processing_appoint_value"
                          :placeholder="
                            $t('editFileType.workflow.template.tip34')
                          "
                        ></el-input>
                        <!-- 链接 -->
                        <el-input
                          v-else-if="processing_appoint_field2Type == 'LINK'"
                          v-model="processing_appoint_value"
                          :placeholder="
                            $t('editFileType.workflow.template.tip37')
                          "
                        ></el-input>
                        <!-- 单用户 -->
                        <el-select
                          v-else-if="
                            processing_appoint_field2Type ==
                            'SINGLE_USER_SELECT'
                          "
                          v-model="processing_appoint_value"
                          :placeholder="
                            $t('editFileType.workflow.template.tip19')
                          "
                          filterable
                        >
                          <el-option
                            v-for="item in userList"
                            :key="item.accountId"
                            :label="item.nickname"
                            :value="item.accountId"
                          >
                          </el-option>
                        </el-select>
                        <!-- 日期选择框 -->
                        <el-date-picker
                          v-else-if="processing_appoint_field2Type == 'DATE'"
                          v-model="processing_appoint_value"
                          type="date"
                          :placeholder="
                            $t('editFileType.workflow.template.tip40')
                          "
                          value-format="yyyy-MM-dd"
                        >
                        </el-date-picker>
                        <!-- 标签 -->
                        <el-select
                          v-else-if="processing_appoint_field2Type == 'LABEL'"
                          v-model="processing_appoint_value"
                          :placeholder="
                            $t('editFileType.workflow.template.tip41')
                          "
                          multiple
                          allow-create
                          filterable
                          clearable
                        >
                          <el-option
                            v-for="item in []"
                            :key="item.accountId"
                            :label="item.nickname"
                            :value="item.accountId"
                          >
                          </el-option>
                        </el-select>
                        <!-- 单选项 -->
                        <el-select
                          v-else-if="processing_appoint_field2Type == 'SELECT'"
                          v-model="processing_appoint_value"
                          :placeholder="
                            $t('editFileType.workflow.template.tip43')
                          "
                          filterable
                          clearable
                        >
                          <el-option
                            v-for="item in field_extraData_1"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                        <!-- 多选项 -->
                        <el-select
                          v-else-if="
                            processing_appoint_field2Type == 'SELECT_MULTI'
                          "
                          v-model="processing_appoint_value"
                          :placeholder="
                            $t('editFileType.workflow.template.tip45')
                          "
                          multiple
                          filterable
                          clearable
                        >
                          <el-option
                            v-for="item in field_extraData_1"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="processing_value" class="buttonContent">
                  <el-button
                    icon="el-icon-plus
"
                    @click="processing_addCondition"
                    >{{ $t("editFileType.workflow.template.tip20") }}</el-button
                  >
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </el-drawer>
    <!-- 添加状态弹窗 -->
    <el-dialog
      :title="$t('editFileType.workflow.newBtn')"
      :visible="dialogFormVisible"
      :close-on-click-modal="false"
      :show-close="true"
      width="25%"
      class="new_status_dialog"
      @close="add_status_dialog(false)"
    >
      <el-form
        ref="addStatusForm"
        :model="status_form"
        label-position="left"
        label-width="100px"
        :rules="rules"
      >
        <el-form-item prop="status_name">
          <template slot="label">
            <span>{{ $t("editFileType.workflow.name") }}</span>
          </template>
          <el-input v-model="status_form.status_name"></el-input>
        </el-form-item>
        <el-form-item prop="status_category">
          <template slot="label">
            <span>{{ $t("editFileType.workflow.type") }}</span>
          </template>
          <el-select
            v-model="status_form.status_category"
            :placeholder="$t('editFileType.workflow.place')"
          >
            <el-option
              :label="$t('canvas.testPlan.status.notStarted')"
              value="TODO"
            ></el-option>
            <el-option
              :label="$t('canvas.testPlan.status.inProgress')"
              value="IN_PROGRESS"
            ></el-option>
            <el-option
              :label="$t('canvas.testPlan.status.completed')"
              value="DONE"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="plain" @click="add_status_dialog(false)">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="submitStatusForm('addStatusForm')">{{
          $t("btn.newBtn")
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 状态转换弹窗 -->
    <el-dialog
      :title="$t('editFileType.workflow.saveForm.title')"
      :visible="status_confirm_visible"
      :close-on-click-modal="false"
      :show-close="true"
      width="25%"
      class="confirm_status_dialog"
      @close="confirm_status_dialog(false)"
    >
      <div class="status_matching">
        <div class="item title">
          <div>{{ $t("editFileType.workflow.saveForm.original") }}</div>
          <div>{{ $t("editFileType.workflow.saveForm.matched") }}</div>
        </div>
        <el-form
          ref="matching_form"
          class="matching_form"
          :model="matching_form"
        >
          <div
            v-for="(item, index) in matching_form.matching_data"
            :key="index"
            class="item"
          >
            <el-tooltip
              v-ak-tooltip-auto-show
              effect="dark"
              :content="item.label"
              placement="bottom"
            >
              <div class="old_item">
                {{ item.label }}
              </div>
            </el-tooltip>
            <el-form-item
              :prop="`matching_data.${index}.newStatusId`"
              :rules="[
                {
                  required: true,
                  message: $t('editFileType.workflow.saveForm.place'),
                  trigger: ['blur', 'change'],
                },
              ]"
            >
              <el-select
                v-model="item.newStatusId"
                class="matching_item"
                :placeholder="$t('placeholder.dropdown')"
              >
                <el-option
                  v-for="i in format_data.nodes"
                  :key="i.id"
                  :label="i.label"
                  :value="i.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="send_matching_status">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 编辑未保存弹窗 -->
    <el-dialog
      :title="$t('review.tip11')"
      :visible="edit_unSave_dialog"
      :close-on-click-modal="false"
      :show-close="true"
      width="25%"
      class="confirm_status_dialog"
      @close="edit_unSave_dialogClose"
    >
      <div>{{ $t("editFileType.workflow.dialog.tip6") }}</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="save_workflow_cancleInEdit">{{
          $t("editFileType.workflow.dialog.tip7")
        }}</el-button>
        <el-button type="primary" @click="save_workflow_inEdit">{{
          $t("editFileType.workflow.dialog.tip8")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Graph, Shape } from "@antv/x6";
import PersonalAvatar from "@/components/personal";
import {
  get_status_list,
  add_status_list,
  get_workflow_resource,
  set_workflow_resource,
  create_workflow_template,
  set_matching_status,
  add_workflow_trigger,
  get_workflow_typeList,
  get_workflow_list,
  delete_workflow_trigger,
  get_conditions_typeList,
  get_conditions_list,
  add_workflow_conditions,
  delete_workflow_conditions,
  get_processing_typeList,
  get_processing_list,
  add_workflow_processing,
  delete_workflow_processing,
  get_workflow_conditions_judgeLogic,
  change_workflow_conditions_judgeLogic,
  workflow_payFeatureValid,
} from "@/network/workflow/index.js";

import { get_filetype_List } from "@/network/fileType/index.js";

import { mapGetters } from "vuex";
import WorkflowTemplate from "./WorkflowTemplate";
import {
  workflow_config,
  registerWorkflowCell,
} from "@/components/workflowGraph/GraphConfig.js";
import { get_jobAuthorities } from "@/network/home/index.js";

export default {
  inject: ["workflow_reload"],
  components: {
    WorkflowTemplate,
    PersonalAvatar,
  },
  props: {
    layer_number: {
      type: String,
      default: "",
    },
    pannel_height: {
      type: Number,
      default: 500,
    },
    field_arr: {
      type: Array,
      default() {
        return [];
      },
    },
    userList: {
      type: Array,
      default() {
        return [];
      },
    },
    workflow_isEdit_pushPath: {
      type: String,
      default: "",
    },
    all_level_result: {
      type: Array,
      default() {
        return [];
      },
    },
    current_page: {
      type: Number,
      default: 1,
    },
    ischange_level: {
      type: Boolean,
      default: false,
    },
    NOT_EMPTY_role_arr: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      processing_Assignee_value: "",
      processing_Assignee_value_label: "",
      tempalte_dialog_visible: false,
      dialogFormVisible: false,
      workflow_list: [],
      workflow_items: [],
      workflow_appoint_status: [],
      workflow_field_arr: [],
      field_extraData: [],
      field_extraData_1: [],
      new_status_cur_type: "",
      new_status_cur_name: "",
      new_status_cur_id: "",
      jobAuthorities: [],
      template_form: {
        template_name: "",
      },
      status_form: {
        status_name: "",
        status_category: "",
      },
      rules: {
        status_name: [
          {
            required: true,
            message: this.$t("editFileType.workflow.place2"),
            trigger: "blur",
          },
        ],
        status_category: [
          {
            required: true,
            message: this.$t("editFileType.workflow.place"),
            trigger: "change",
          },
        ],
      },
      status_confirm_visible: false,
      matching_form: {
        matching_data: [],
      },

      //工作流画布
      graph: null,
      all_in: false,
      checked_list: {},
      checkList: [],
      all_level_info: [],
      selected_node: "",
      next_appoint_status: [],
      init_node: "",
      isedge_move: false,
      edgeTarget_nodeId: "",
      edgeTarget_nodeId_old: "",
      workflow_data: null, //
      workflow_data_format: null, // 用于画布展示
      new_node_x: -60,
      new_node_y: -60,
      new_node_width: null,
      custom_width: false,
      init_ball: workflow_config.init_ball, // 小灰球
      init_edge: workflow_config.init_edge, // 小灰球的边
      format_data: {
        nodes: [],
        edges: [],
      },

      /* 工作流功能 */
      dialogWorkflowFn: false,
      activeIndex: ["conditions", "auto-trig", "post-processing"],
      workflowFn_data: {
        // 起始状态
        fromStatusId: "",
        // 目标状态
        toStatusId: "",
        // 触发器类型
        triggerType: "",
        // 工作流ID
        workflowId: "",
        // 携带参数
        parameters: {},
        // 项目ID
        projectId: "",
        // 选中线的ID
        triggerId: "",
        // 验证条件类型
        validatorType: "",
        // 验证条件ID
        validatorId: "",
        // 指定多个用户
        usersId: [],
        // 后处理类型
        postHandlerType: "",
        // 是否为all状态
        isall: false,
      },
      // 工作流触发器类型列表
      workflowFn_trigger_typeList: [],
      workflowFn_trigger_typeListNow: [],
      workflowFn_trigger_typeListAll: [],
      workflowFn_trigger_typeListSelect: [],
      autoTrig_value: "",
      autoTrigStatus_value: [],
      autoTrigStatus_on_value: "",
      autoTrigStatus_on_label: "",
      autoTrigStatus_label: [],
      isEdit: false,
      next_appoint_filetype: [],
      back_file: "",
      back_file: "",
      back_status: [],

      // 工作流验证条件
      judgeLogic: "AND",
      workflowFn_conditions_typeList: [],
      workflowFn_conditions_typeListNow: [],
      workflowFn_conditions_typeListAll: [],
      workflowFn_conditions_typeListSelect: [],
      conditions_change_index: 1,
      conditions_change_option: [
        {
          judgeLogic: "AND",
          label: this.$t("editFileType.workflow.dialog.tip12"),
        },
        {
          judgeLogic: "OR",
          label: this.$t("editFileType.workflow.dialog.tip13"),
        },
      ],
      conditions_value: "",
      conditions_Status_value: "",
      conditions_Status_label: "",
      conditions_appoint_field: "",
      conditions_appoint_field_label: "",
      conditions_appoint_value: "",
      conditions_appoint_value_label: "",
      conditions_appoint_users: [],
      conditions_appoint_users_label: [],
      conditions_common_field1: "",
      conditions_common_field2: "",
      conditions_common_field1_label: "",
      conditions_common_field2_label: "",
      conditions_Empty_value: "",
      conditions_job_value: [],
      // 工作流后处理
      workflowFn_processing_typeList: [],
      workflowFn_processing_typeListNow: [],
      workflowFn_processing_typeListAll: [],
      workflowFn_processing_typeListSelect: [],
      processing_value: "",
      processing_Status_value: "",
      processing_Status_value_label: "",
      processing_appoint_field: "",
      processing_appoint_value: "",
      processing_appoint_field_label: "",
      processing_appoint_value_label: "",

      // loading标识
      isAutotrig_loading: false,
      isConditions_loading: false,
      isProcessing_loading: false,

      // graph的dom
      graphDom_node_edge: [],
      graphDom_node: [],
      graphDom_edge: [],
      graphDom_delete_btn: [],

      // 编辑后未保存弹窗
      edit_unSave_dialog: false,

      // 是否编辑
      workflow_isEdit: false,
      onSave_workflow: false,
      workflow_isNodeAdd: false,
      workflow_isAllIn: false,

      // 节点label
      workflow_source_label: "",
      workflow_target_label: "",
      p_id: this.get_pid(),
    };
  },
  computed: {
    ...mapGetters(["page_typeid", "user_list", "project_name"]),
    project_user() {
      let l = [];
      Object.keys(this.user_list).forEach((item) => {
        if (!this.user_list[item].deleted) {
          let obj = JSON.parse(JSON.stringify(this.user_list[item]));
          if (!obj.projectNames.includes(this.project_name)) {
            obj.nickname =
              obj.nickname +
              (this.$t("fta.type") == "中文"
                ? " (不在项目中) "
                : " ( Not in the project )");
          }
          l.push(obj);
        }
      });
      return l;
    },
    shapeType() {
      let value = "";
      let type = this.new_status_cur_type;
      switch (type) {
        case "TODO":
          value = "todo-rect";
          break;
        case "IN_PROGRESS":
          value = "processing-rect";
          break;
        case "DONE":
          value = "done-rect";
          break;
      }
      return value;
    },
    new_node_width_auto() {
      return this.custom_width ? this.new_node_width : 110;
    },
    matching_data_format() {
      return this.matching_form.matching_data.map((item) => {
        return { newStatusId: item.newStatusId, oldStatusId: item.oldStatusId };
      });
    },
    conditions_common_field2List() {
      let fieldType = "";
      let temArr = [];
      this.workflow_field_arr.forEach((item) => {
        if (this.conditions_common_field1 == item.fieldId) {
          fieldType = item.type;
        }
      });
      this.workflow_field_arr.forEach((item) => {
        if (
          item.type == fieldType &&
          item.fieldId != this.conditions_common_field1
        ) {
          temArr.push(item);
        }
      });
      return temArr;
    },
    conditions_appoint_field2Type() {
      let fieldType = "";
      this.workflow_field_arr.forEach((item) => {
        if (item.fieldId == this.conditions_appoint_field) {
          fieldType = item.type;
        }
      });
      return fieldType;
    },
    processing_appoint_field2Type() {
      let fieldType = "";
      this.workflow_field_arr.forEach((item) => {
        if (item.fieldId == this.processing_appoint_field) {
          fieldType = item.type;
        }
      });
      return fieldType;
    },
    // 是否编辑
    workflow_isEdit_all() {
      return (
        this.workflow_isEdit ||
        this.workflow_isNodeAdd ||
        this.workflow_isAllIn ||
        this.isedge_move
      );
    },
  },
  watch: {
    layer_number() {
      this.graph.dispose();
      this.get_workflow_data();
    },
    workflow_data_format: {
      handler() {
        this.checkList = [];
        this.checked_list = {};
        this.workflow_data_format.nodes.forEach((item, index) => {
          if (item.data) {
            this.checked_list[item.id] = item.data.allIn;
            if (item.data.allIn) this.checkList.push(item.id);
          }
        });
      },
      deep: true,
    },
    workflow_isEdit_all: {
      handler(newvalue) {
        // let sign =
        //   this.workflow_isEdit ||
        //   this.workflow_isNodeAdd ||
        //   this.workflow_isAllIn ||
        //   this.isedge_move ||
        //   this.ischange_level;
        this.$emit("workflow_isEdit", newvalue);
      },
      immediate: true,
      deep: true,
    },
    workflow_items(newValue) {
      this.workflow_appoint_status = [];
      this.workflow_list.forEach((item1) => {
        let flag = true;
        this.workflow_items.forEach((item2) => {
          if (item1.statusId == item2.statusId) {
            flag = false;
            return;
          }
        });
        if (flag) {
          this.workflow_appoint_status.push(item1);
        }
      });
    },
    field_arr: {
      handler() {
        this.workflow_field_arr = [];
        this.field_arr.forEach((item) => {
          if (item.type != "DRAWIO") {
            this.workflow_field_arr.push(item);
          }
        });
      },
      immediate: true,
    },
  },
  async mounted() {
    registerWorkflowCell(); // 自定义图形
    await this.status_list();
    await this.get_workflow_data();
    get_filetype_List(this.get_pid()).then((res) => {
      this.next_appoint_filetype = res;
    });
  },
  beforeDestroy() {
    this.graph.dispose();
  },
  methods: {
    async status_list() {
      await get_status_list(this.get_pid()).then((res) => {
        this.workflow_list = res;
      });
    },

    async get_workflow_data() {
      await get_workflow_resource(
        this.get_pid(),
        this.page_typeid,
        this.layer_number
      ).then((res) => {
        this.workflow_data = res; // 保存无小灰球的接口数据
        this.workflow_data_format = JSON.parse(JSON.stringify(res)); // copy一份，指向另外空间
        this.render_workflow();
        // 获取工作流触发器类型列表
        get_workflow_typeList().then((res) => {
          this.workflowFn_trigger_typeList = res;
        });
        // 获取工作流ID及项目ID
        this.workflowFn_data.workflowId = res.workflowId;
        this.workflowFn_data.projectId = res.projectId;

        // 获取工作流验证条件类型列表
        get_conditions_typeList().then((res) => {
          this.workflowFn_conditions_typeList = res;
        });

        // 获取工作流后处理类型列表
        get_processing_typeList().then((res) => {
          this.workflowFn_processing_typeList = res;
        });
      });
      // 获取职位信息
      await get_jobAuthorities(this.get_pid()).then((res) => {
        this.jobAuthorities = res;
      });

      // 获取所有层级的信息
      let level_layerNumber = [];
      this.next_appoint_status = [];
      this.all_level_result.forEach((item) => {
        level_layerNumber.push(item.layerNumber);
      });
      if (this.current_page == level_layerNumber.length) {
        // 当仅剩一个节点或处于最后一个节点时
        await get_workflow_resource(
          this.get_pid(),
          this.page_typeid,
          level_layerNumber[this.current_page - 1]
        ).then((res) => {
          let next = res;
          next.nodes.forEach((item1) => {
            this.workflow_list.forEach((item2) => {
              if (item1.label == item2.name) {
                this.next_appoint_status.push(item2);
              }
            });
          });
        });
      } else {
        await get_workflow_resource(
          this.get_pid(),
          this.page_typeid,
          level_layerNumber[this.current_page]
        ).then((res) => {
          let next = res;
          next.nodes.forEach((item1) => {
            this.workflow_list.forEach((item2) => {
              if (item1.label == item2.name) {
                this.next_appoint_status.push(item2);
              }
            });
          });
        });
      }

      this.edgeTarget_nodeId_old = this.graph.getEdges()[0].getTarget().cell;
    },
    create_graph() {
      let _this = this;
      // if(this.graph) {
      //   this.graph.dispose()
      // }
      this.graph = new Graph({
        container: document.getElementById("container"),
        width: "100%",
        height: 700,
        panning: true, // 开启画布平移
        history: {
          enabled: true,
          ignoreChange: true,
        },
        grid: {
          size: 20, // 网格大小 10px
          visible: true, // 渲染网格背景
        },
        mousewheel: {
          enabled: true,
          modifiers: ["ctrl", "meta"],
        },
        connecting: {
          router: {
            name: "manhattan",
            args: {
              padding: {
                top: 35,
                bottom: 35,
                left: 25,
                right: 25,
              },
            },
          },
          // anchor: "center",
          allowBlank: false,
          allowNode: false,
          allowMulti: false,
          allowLoop: false,
          allowEdge: false,
          allowPort: true,
          highlight: true,
          snap: {
            radius: 20,
          },
          createEdge() {
            return new Shape.Edge({
              shape: "grey-edge",
              connector: {
                name: "rounded",
                args: {
                  radius: 10,
                },
              },
              attrs: {
                line: {
                  stroke: "#A6A6A6",
                  strokeWidth: 2,
                  targetMarker: {
                    name: "classic",
                    size: 8,
                  },
                },
              },
              zIndex: 0,
            });
          },
          // 判断移动的是初始状态的边，节点做相应处理
          validateEdge({ edge, previous }) {
            let cell = _this.graph.getCellById(previous.cell);
            if (cell == null) return true;
            else if (
              cell.getData().initialStatus &&
              previous.cell !== edge.target.cell //解决如果拖拽线条导致初始状态消失的问题
            ) {
              cell.setData({ initialStatus: false });
            }
            return true;
          },
          // 改变节点为初始状态
          validateConnection({ sourceCell, targetCell }) {
            if (sourceCell.id == "grey_ball") {
              this.getNodes().forEach((item) => {
                if (item.id != "grey_ball") {
                  item.setData({ initialStatus: false });
                }
              });
              targetCell.setData({ initialStatus: true });
            }
            return true;
          },
        },
      });

      //加入allin节点
      this.workflow_data_format.nodes
        .filter((item) => {
          return item.data && item.data.allIn;
        })
        .forEach((ele) => {
          let allin_node = {
            shape: "allin-rect",
            x: ele.x + 240,
            y: ele.y,
            id: `${ele.id}-allin`,
          };
          let allin_edge = {
            shape: "custom-edge-label",
            source: allin_node.id,
            target: ele.id,
            attrs: {
              line: {
                stroke: "#A6A6A6",
              },
            },
            labels: [
              {
                attrs: {
                  line: {
                    stroke: "#73d13d",
                  },
                  text: {
                    text: "+",
                  },
                },
              },
            ],
          };
          this.workflow_data_format.nodes.push(allin_node);
          this.workflow_data_format.edges.push(allin_edge);
        });
      // 加入小灰球
      let [init_target] = this.workflow_data_format.nodes.filter((item) => {
        return item.data && item.data.initialStatus == true;
      });

      this.init_edge.target = init_target.id;
      this.workflow_data_format.nodes.unshift(this.init_ball);
      this.workflow_data_format.edges.unshift(this.init_edge);
      this.workflow_data_format.edges.forEach((edge) => {
        if (edge.shape === "grey-edge" && edge.source !== "grey_ball") {
          edge.shape = "custom-edge-label";
          edge.attrs = {
            line: {
              stroke: "#A6A6A6",
            },
          };
          edge.labels = [
            {
              attrs: {
                line: {
                  stroke: "#73d13d",
                },
                text: {
                  text: "+",
                },
              },
            },
          ];
        }
      });
      this.workflow_data_format.nodes.forEach((node) => {
        if (node.label) {
          const width = this.get_node_width(node.label);
          node.width = width > 200 ? width : 200;
          node.height = 30;
        }
      });
      this.graph.fromJSON(this.workflow_data_format);
      this.graph.zoom(-0.2);
      this.graph.centerContent(); // 内容显示在画布中央
    },
    render_workflow() {
      let _this = this;
      this.create_graph();
      this.format_workflow_item(); // 增加状态列表过滤
      // 控制连接桩显示/隐藏
      const showPorts = (ports, show) => {
        for (let i = 0, len = ports.length; i < len; i = i + 1) {
          ports[i].style.visibility = show ? "visible" : "hidden";
        }
      };
      //添加删除节点工具
      this.graph.on("node:mouseenter", ({ node }) => {
        const container = document.getElementById("container");
        const ports = container.querySelectorAll(".x6-port-body");

        let isRootConnect_flag = false;
        let rootEdge_id = this.graph.getEdges()[0].id;

        if (this.edgeTarget_nodeId_old != node.id) {
          this.isedge_move = true;
          this.edgeTarget_nodeId_old = this.graph
            .getEdges()[0]
            .getTarget().cell;
        } else {
          this.isedge_move = false;
        }

        let nodeConnect_Edges = this.graph.getIncomingEdges(node) || [];
        nodeConnect_Edges.forEach((item) => {
          if (item.id == rootEdge_id) {
            isRootConnect_flag = true;
          }
        });

        showPorts(ports, true);
        let isallin = node.store.data.shape;
        if (
          node.id != "grey_ball" &&
          isallin != "allin-rect" &&
          !isRootConnect_flag
        ) {
          node.addTools({
            name: "button-remove",
            args: {
              x: 3,
              y: 3,
              onClick({ cell, edge }) {
                // if (cell.getData().initialStatus) {
                //   _this.$message.warning(
                //     _this.$t("editFileType.workflow.info2")
                //   );
                // } else {
                //   let allin_node = null;
                //   if (cell.getData().allIn) {
                //     allin_node = _this.graph.getCellById(`${cell.id}-allin`);
                //   }
                //   // 删除节点并且删除其all节点
                //   _this.graph.removeNode(cell);
                //   _this.graph.removeNode(allin_node);
                //   // let obj = _this.workflow_list.find((item) => {
                //   //   return item.name == cell.id;
                //   // });
                //   // if (obj) _this.workflow_items.push(obj);
                // }

                let allin_node = null;
                if (cell.getData().allIn) {
                  allin_node = _this.graph.getCellById(`${cell.id}-allin`);
                }
                // 删除节点并且删除其all节点
                _this.graph.removeNode(cell);
                _this.graph.removeNode(allin_node);
              },
            },
          });
        }
      });
      this.graph.on("node:mouseleave", ({ node }) => {
        const container = document.getElementById("container");
        const ports = container.querySelectorAll(".x6-port-body");
        showPorts(ports, false);
        node.removeTools();
      });
      // 显示allin面板
      this.graph.on("node:click", ({ cell, e }) => {
        if (cell.id != "grey_ball") {
          this.selected_node = cell.id;
          this.all_in = true;
        }
      });
      this.graph.on("blank:click", ({ cell, e }) => {
        this.all_in = false;
      });
      this.graph.on("node:added", ({ node, index, options }) => {
        // 增加状态列表过滤
        this.workflow_items = this.workflow_items.filter((item) => {
          return item.statusId != node.id;
        });
        // 更新allin复选框
        let new_data = _this.graph.toJSON().cells.filter((item) => {
          return item.data;
        });
        this.workflow_data_format.nodes = new_data;
        // this.workflow_data.nodes = new_data;
      });
      this.graph.on("node:removed", ({ node, index, options }) => {
        // 增加状态列表过滤
        if (node.getData()) {
          node.setData({ allIn: false });
          let obj = _this.workflow_list.find((item) => {
            return item.statusId == node.id;
          });
          if (obj) _this.workflow_items.push(obj);
        }
        // 更新allin复选框
        let new_data = _this.graph.toJSON().cells.filter((item) => {
          return item.data;
        });
        this.workflow_data_format.nodes = new_data;
        // this.workflow_data.nodes = new_data;
      });
      // 添加移动边工具
      this.graph.on("edge:mouseenter", ({ cell, edge, view }) => {
        const list = [];

        if (
          edge.getSourceNode().store.data.id !== "grey_ball" &&
          edge.getSourceNode().id.indexOf("allin") == -1
        ) {
          list.push({
            name: "button-remove",
            args: {
              onClick({ cell }) {
                _this.graph.removeEdge(cell.id);
              },
            },
          });
        }
        list.push({
          name: "target-arrowhead",
          args: {
            attrs: {
              fill: "rgba(48, 100, 143, 1)",
            },
          },
        });
        cell.addTools(list);
      });
      this.graph.on("edge:mouseleave", ({ cell }) => {
        cell.removeTools();
      });

      // // 工作流自动化能力的引入(监听)
      // this.graph.on("edge:click", ({ edge }) => {
      //   // 部分初始化
      //   this.dialogWorkflowFn = true;
      //   // 取得来源与目标状态ID
      //   this.workflowFn_data.fromStatusId = edge.source.cell;
      //   this.workflowFn_data.toStatusId = edge.target.cell;
      //   // 获取工作流触发器列表
      //   this.get_update_workflow_list();
      //   // 获取工作流验证条件列表
      //   this.get_update_workflow_conditionsList();
      //   // 获取后处理列表
      //   this.get_update_workflow_processing();
      // });

      // 监听编辑与非编辑
      this.$watch(
        "isEdit",
        (newValue) => {
          // 获取删除按钮
          this.graphDom_delete_btn = document.getElementsByClassName(
            "x6-graph-svg-decorator"
          );
          if (newValue) {
            /* 编辑状态 */

            // 允许节点点交互
            this.graphDom_node.forEach((item) => {
              item.classList.remove("eventStop");
            });
            // 禁止工作流功能
            this.graph.listeners["edge:click"] = [];

            // 启用删除按钮
            this.graphDom_delete_btn[1].style.display = "block";
            // 编辑状态时候隐藏所有的label
            const tempObj = JSON.parse(
              JSON.stringify(this.workflow_data_format)
            );
            tempObj.edges.forEach((edge) => {
              edge.shape = "grey-edge";
              delete edge.labels;
              delete edge.attrs;
            });
            this.graph.fromJSON(tempObj);
          } else {
            /* 非编辑状态 */
            this.graph.fromJSON(this.workflow_data_format);
            // 关闭allin框
            this.all_in = false;
            // 获取所有节点以及边的dom
            this.graphDom_node_edge =
              document.getElementsByClassName("x6-graph-svg-stage")[0].children;
            this.graphDom_node = [];
            this.graphDom_edge = [];
            this.graphDom_node_edge.forEach((item) => {
              if (
                item.classList.value == "x6-cell x6-node" ||
                item.classList.value == "x6-cell x6-node eventStop"
              ) {
                this.graphDom_node.push(item);
              } else if (
                item.classList.value == "x6-cell x6-edge" ||
                item.classList.value == "x6-cell x6-edge eventStop"
              ) {
                this.graphDom_edge.push(item);
              }
            });

            // 禁止删除
            this.graphDom_delete_btn[0].style.display = "none";

            // 禁止节点交互
            this.graphDom_node.forEach((item) => {
              item.classList.add("eventStop");
            });

            // 禁止初始状态工作流自动化
            // this.graphDom_edge.forEach((item, index) => {
            //   if (index == 0) {
            //     item.classList.add("curStop");
            //   }
            // });

            // 启用工作流功能
            this.graph.on("edge:click", ({ cell, edge }) => {
              if (edge.getSource().cell != "grey_ball") {
                // 取得来源与目标状态ID
                this.workflowFn_data.fromStatusId = edge.source.cell;
                this.workflowFn_data.toStatusId = edge.target.cell;
                // 获取来源于目标名称
                this.workflow_list.forEach((item) => {
                  if (item.statusId == this.workflowFn_data.fromStatusId) {
                    this.workflow_source_label = item.name;
                  } else if (item.statusId == this.workflowFn_data.toStatusId) {
                    this.workflow_target_label = item.name;
                  }
                });
                if (edge.getSourceNode().id.indexOf("allin") != -1) {
                  // all状态节点
                  this.workflowFn_data.isall = true;
                  this.workflowFn_data.fromStatusId = "all";
                  this.workflow_source_label = "all";
                } else {
                  this.workflowFn_data.isall = false;
                }

                this.dialogWorkflowFn = true;
                // 获取工作流触发器列表
                this.get_update_workflow_list();
                // 获取工作流验证条件列表
                this.get_update_workflow_conditionsList();
                // 获取后处理列表
                this.get_update_workflow_processing();
                // 获取校验器的判断逻辑
                get_workflow_conditions_judgeLogic(
                  this.workflowFn_data.projectId,
                  {
                    fromStatusId: this.workflowFn_data.fromStatusId,
                    toStatusId: this.workflowFn_data.toStatusId,
                    workflowId: this.workflowFn_data.workflowId,
                  }
                ).then((res) => {
                  this.judgeLogic = res.judgeLogic;
                });
              }
            });
          }
        },
        { immediate: true }
      );

      // 启用 x6 历史记录
      this.use_isEdit();
    },

    get_node_width(label) {
      let len = label.length;
      if (/[\u4E00-\u9FA5]+/g.test(label)) {
        return len * 17;
      } else {
        return len * 11;
      }
    },
    set_node_width() {
      // 动态设置新添加的节点宽度,若有汉字则倍数20
      let len = this.new_status_cur_name.length;
      this.custom_width = true;
      if (/[\u4E00-\u9FA5]+/g.test(this.new_status_cur_name)) {
        this.new_node_width = len * 17;
      } else {
        this.new_node_width = len * 11;
      }
    },
    add_workflow_item(val) {
      if (val) {
        let obj = this.workflow_items.find((item) => {
          return item.statusId == val;
        });
        this.new_status_cur_name = obj.name;
        this.new_status_cur_type = obj.statusCategory;
        this.new_status_cur_id = obj.statusId;
        this.set_node_width();
        this.addNodes();
        this.workflow_isNodeAdd = true;
      }
    },
    show_addtemplate_dialog(val) {
      if (!val) {
        this.$nextTick(() => {
          this.$refs.addTemplateForm.resetFields();
        });
        this.tempalte_dialog_visible = false;
      } else {
        this.tempalte_dialog_visible = true;
      }
    },
    submitTemplateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.create_format_data();
          let data = {
            name: this.template_form.template_name,
            workflowDetailDto: this.format_data,
          };
          create_workflow_template(this.get_pid(), data).then((res) => {
            this.tempalte_dialog_visible = false;
            this.$message.success(this.$t("editFileType.workflow.success"));
          });
        } else {
          return false;
        }
      });
    },
    add_status_dialog(val) {
      if (!val) {
        this.$nextTick(() => {
          this.$refs["addStatusForm"].resetFields();
          this.custom_width = false;
        });
        this.dialogFormVisible = false;
      } else {
        this.dialogFormVisible = true;
      }
    },
    submitStatusForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.new_status_cur_type = this.status_form.status_category;
          this.new_status_cur_name = this.status_form.status_name;
          this.set_node_width();
          let data = {
            name: this.new_status_cur_name,
            statusCategory: this.new_status_cur_type,
          };
          add_status_list(this.get_pid(), data).then((res) => {
            this.new_status_cur_id = res.statusId;
            this.$message.success(this.$t("user.userTable.message.addSuccess"));
            this.addNodes();
            this.dialogFormVisible = false;
            this.status_list();
          });
        } else {
          return false;
        }
      });
    },
    addNodes() {
      // 设置新生成节点的随机位置
      let random_num = Math.random() * 200;
      this.graph.addNode({
        id: this.new_status_cur_id,
        shape: this.shapeType,
        x: this.new_node_x + random_num,
        y: this.new_node_y + random_num,
        width: this.new_node_width_auto > 200 ? this.new_node_width_auto : 200,
        height: 30,
        label: this.new_status_cur_name,
        data: {
          allIn: false,
          initialStatus: false,
          label: this.new_status_cur_name,
        },
      });
    },
    create_format_data() {
      let workflow_arr = this.graph.toJSON().cells;
      Object.keys(this.workflow_data_format).forEach((item) => {
        if (item != "nodes" && item != "edges") {
          this.format_data[item] = this.workflow_data_format[item];
        }
      });

      workflow_arr
        .filter((item) => {
          return item.data && item.attrs;
        })
        .forEach((ele) => {
          let obj = {
            data: ele.data,
            id: ele.id,
            shape: ele.shape,
            x: ele.position.x,
            y: ele.position.y,
            label: ele.attrs.text.text,
          };

          this.format_data.nodes.push(obj);
        });

      workflow_arr
        .filter((item) => {
          return (
            item.shape == "grey-edge" &&
            item.source.cell != "grey_ball" &&
            !item.source.cell.endsWith("-allin")
          );
        })
        .forEach((ele) => {
          let obj = {
            shape: ele.shape,
            source: Object.assign({ port: null }, ele.source),
            target: Object.assign({ port: null }, ele.target),
          };
          this.format_data.edges.push(obj);
        });
    },
    status_matching() {
      let new_ids = this.format_data.nodes.map((item) => item.id);
      this.matching_form.matching_data = this.workflow_data.nodes
        .filter((item) => {
          return !new_ids.includes(item.id);
        })
        .map((ele) => {
          let obj = {
            newStatusId: "",
            oldStatusId: ele.id,
            label: ele.label,
          };
          return obj;
        });
      return this.matching_form.matching_data.length > 0;
    },
    save_workflow() {
      this.matching_form.matching_data = [];
      this.format_data = {
        nodes: [],
        edges: [],
      };
      this.create_format_data();
      let is_change = this.status_matching();

      if (is_change) {
        this.status_confirm_visible = true;
      } else {
        // this.edit_unSave_dialog = true;
        this.isEdit = false;
        this.workflow_isEdit = false;
        this.workflow_isNodeAdd = false;
        this.workflow_isAllIn = false;
        this.isedge_move = false;
        this.send_workflow(true);
      }
    },
    send_workflow(flag) {
      let params = {
        projectId: this.get_pid(),
        fileTypeId: this.page_typeid,
        layerNumber: this.layer_number,
        data: this.format_data,
      };
      set_workflow_resource({ ...params }).then((res) => {
        this.workflow_isEdit = false;
        this.$emit("get_workflow_position");
        if (flag) {
          this.$message.success(
            this.$t("editFileType.workflow.message.saveSuccess")
          );
        }
        this.graph.dispose();
        // this.workflow_reload();
        registerWorkflowCell(); // 自定义图形
        this.status_list();
        this.get_workflow_data();
        if (this.workflow_isEdit_pushPath) {
          let temPath = this.workflow_isEdit_pushPath;
          this.workflow_isEdit_pushPath = "";
          this.$router.push(temPath);
        } else if (this.ischange_level) {
          // this.$parent.gonext1();
          this.$emit("gonext1");
        }
      });
    },
    add_allin_node(checked, node_target_id) {
      let cell = this.graph.getCellById(node_target_id);
      let x = cell.store.data.position.x + 150;
      let y = cell.store.data.position.y;
      if (checked) {
        cell.setData({ allIn: true });
        this.graph.addNode({
          id: `${cell.id}-allin`,
          shape: "allin-rect",
          x: x,
          y: y,
        });
        this.graph.addEdge({
          shape: "grey-edge",
          source: `${cell.id}-allin`,
          target: cell.id,
        });
      } else {
        cell.setData({ allIn: false });
        this.graph.removeNode(`${cell.id}-allin`);
      }
      this.workflow_isAllIn = true;
    },
    set_template(template_data) {
      this.graph.dispose();
      this.workflow_data_format = JSON.parse(JSON.stringify(template_data));
      this.render_workflow();
    },
    format_workflow_item() {
      // 新增状态列表过滤初始画布中状态
      let exist_status = this.graph
        .toJSON()
        .cells.filter((item) => {
          return item.data;
        })
        .map((ele) => {
          return ele.id;
        });

      this.workflow_items = this.workflow_list.filter((item) => {
        return exist_status.indexOf(item.statusId) == -1;
      });
    },
    confirm_status_dialog(val) {
      if (!val) {
        this.$refs["matching_form"].resetFields();
        this.status_confirm_visible = false;
      } else {
        this.status_confirm_visible = true;
      }
    },
    send_matching_status() {
      this.$refs["matching_form"].validate((valid) => {
        if (valid) {
          let params = {
            projectId: this.get_pid(),
            fileTypeId: this.page_typeid,
            layerNumber: this.layer_number,
            data: this.matching_form.matching_data,
          };
          set_matching_status({ ...params }).then(() => {
            this.status_confirm_visible = false;
            this.send_workflow(true);
          });
        } else {
          return false;
        }
      });
      this.isEdit = false;
      this.workflow_isEdit = false;
      this.workflow_isNodeAdd = false;
      this.workflow_isAllIn = false;
      this.isedge_move = false;
    },
    handleWorkflowFn(key, keyPath) {
      this.activeIndex = key;
    },
    // 初始化自动触发工作流表
    autoTrig_workflowInit() {
      // 搜索推进状态
      this.autoTrigStatus_label = [];
      this.workflowFn_trigger_typeListNow.forEach((item1) => {
        if (item1.triggerType == "STATUS_BARRIER") {
          this.autoTrigStatus_value = item1.statusId;
          this.workflow_list.forEach((item2) => {
            item1.statusId.forEach((id) => {
              if (item2.statusId == id) {
                // 更新下拉框文本和显示文本
                this.autoTrigStatus_label.push(item2.name);
              }
            });
          });
        }
        if (item1.triggerType == "NODE_RELATION_STATUS_BARRIER") {
          this.workflow_list.forEach((item2) => {
            console.log(item2);
          });
        }
      });
      this.isAutotrig_loading = false;
    },
    // 初始化验证条件工作流表
    conditions_workflowInit() {
      this.isConditions_loading = false;
    },
    // 初始化后处理工作流表
    processing_workflowInit() {
      this.workflowFn_processing_typeListNow.forEach((item1) => {
        if (item1.postHandlerType == "ASSIGNEE_MODIFY_TO_SPECIFIED_USER") {
          this.project_user.forEach((ele) => {
            if (ele.accountId == item1.assignee) {
              this.processing_Assignee_value_label = ele.nickname;
            }
          });

          this.processing_Assignee_value = item1.assignee;
        }
        if (item1.postHandlerType == "CHILD_STATUS_MODIFY") {
          this.workflow_list.forEach((item2) => {
            if (item2.statusId == item1.statusId) {
              this.processing_Status_value = item2.name;
              this.processing_Status_value_label = item2.name;
            }
          });
        } else if (item1.postHandlerType == "FIELD_MODIFY") {
          let field2Type = "";
          this.workflow_field_arr.forEach((item2) => {
            if (item2.fieldId == item1.fieldId) {
              // 更新下拉框文本和显示文本
              // this.conditions_appoint_field = item2.name;
              this.processing_appoint_field_label = item2.name;
              // this.conditions_appoint_value = item1.value;
              field2Type = item2.type;
            }
          });
          if (
            field2Type ==
              this.$t("fieldLab.addNewFieldForm.fieldTypeList.number") ||
            field2Type == this.$t("editFileType.workflow.template.tip35") ||
            field2Type == this.$t("editFileType.workflow.template.tip36") ||
            field2Type == this.$t("editFileType.workflow.template.tip38")
          ) {
            // 数字、单行文本、多行文本、链接类型
            this.processing_appoint_value_label = item1.value;
          } else if (
            field2Type ==
            this.$t("fieldLab.addNewFieldForm.fieldTypeList.multiUser")
          ) {
            // 多用户类型
            let fieldName = [];
            item1.value.forEach((item3) => {
              this.userList.forEach((item4) => {
                if (item4.accountId == item3) {
                  fieldName.push(item4.nickname);
                }
              });
            });
            this.processing_appoint_value_label = fieldName;
          } else if (
            field2Type == this.$t("fieldLab.addNewFieldForm.fieldTypeList.user")
          ) {
            // 单用户类型
            this.userList.forEach((item5) => {
              if (item1.value == item5.accountId) {
                this.processing_appoint_value_label = item5.nickname;
              }
            });
          } else if (
            field2Type == this.$t("editFileType.workflow.template.tip39")
          ) {
            // 日期类型
            this.processing_appoint_value_label = item1.value.slice(0, 10);
          } else if (
            field2Type == this.$t("editFileType.workflow.template.tip42")
          ) {
            // 标签类型
            this.processing_appoint_value_label = item1.value;
          } else if (
            field2Type == this.$t("editFileType.workflow.template.tip44")
          ) {
            // 单选项类型
            this.processing_appoint_value_label = item1.value;
          } else if (
            field2Type == this.$t("editFileType.workflow.template.tip46")
          ) {
            // 多选项类型
            this.processing_appoint_value_label = item1.value;
          }
        }
      });
      this.isProcessing_loading = false;
    },
    // 添加自动触发
    autoTrig_addCondition(data) {
      if (this.autoTrig_value == "NODE_RELATION_STATUS_BARRIER") {
        if (this.back_file.length < 1 || this.back_status.length < 1) {
          this.$message({
            type: "warning",
            message: this.$t("model_view.tip4"),
          });
          return;
        }
      }
      // 付费功能查验
      let workflowFn = data ? data : this.workflowFn_data;
      workflow_payFeatureValid(
        workflowFn.projectId,
        workflowFn.workflowId
      ).then((res) => {
        if (res) {
          // 可以使用
          console.log(workflowFn.projectId, workflowFn, this.workflowFn_data);

          add_workflow_trigger(workflowFn.projectId, workflowFn).then((res) => {
            this.get_update_workflow_list();
            this.autoTrig_value = "";
            this.workflowFn_data.parameters = {};
            this.isAutotrig_loading = true;
          });
        } else {
          this.$message({
            duration: 0,
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: `<span>${this.$t("certificate.Please")}<a href="${
              window.location.origin
            }/profile/certificate_management?target=workflow" target="_blank" style="color: blue">${this.$t(
              "certificate.CertificateManagement"
            )}</a>${this.$t("certificate.InstallComponents")}</span>`,
          });
        }
      });
    },
    // 删除自动触发
    autoTrig_deleteCondition(triggerId, flag) {
      delete_workflow_trigger(this.workflowFn_data.projectId, triggerId).then(
        () => {
          this.isAutotrig_loading = true;
          if (flag) {
            // 增加该条件
            this.workflowFn_data.parameters.statusIdList =
              this.autoTrigStatus_value;
            this.workflowFn_data.triggerType = "STATUS_BARRIER";
            this.autoTrig_addCondition();
          } else {
            this.autoTrigStatus_value = [];
            this.back_file = [];
            this.get_update_workflow_list();
          }
        }
      );
    },
    // 选中自动触发
    select_autoTrig(value) {
      if (value == "NODE_RELATION_STATUS_BARRIER") {
        this.back_status = null;
        this.back_file = null;
      }

      this.workflowFn_data.triggerType = value;
    },
    // 选中自动触发选中条件
    select_autoTrigStatus(flag, item) {
      if (flag == "zdh") {
        this.workflowFn_data.parameters.statusIdList = this.back_status;

        return;
      }
      if (flag) {
        let data = JSON.parse(JSON.stringify(this.workflowFn_data));
        data.parameters.statusIdList = this.back_status;
        data.parameters.fileTypeId = this.back_file;
        console.log(data, 987654);

        data.triggerType = "NODE_RELATION_STATUS_BARRIER";

        delete_workflow_trigger(data.projectId, item.triggerId).then(() => {
          this.autoTrig_addCondition(data);
        });
      } else {
        this.workflowFn_data.parameters.statusIdList =
          this.autoTrigStatus_value;
      }
    },
    select_autoTrigFileType(flag, item) {
      if (flag) {
        console.log(item, 78788);
        let data = JSON.parse(JSON.stringify(this.workflowFn_data));
        data.parameters.statusIdList = this.back_status;
        data.parameters.fileTypeId = this.back_file;
        data.triggerType = "NODE_RELATION_STATUS_BARRIER";

        delete_workflow_trigger(data.projectId, item.triggerId).then(() => {
          this.autoTrig_addCondition(data);
        });
      } else {
        this.workflowFn_data.parameters.fileTypeId = this.back_file;
      }
    },
    // 自动触发：选中已保存的推送状态
    select_on_autoTrigStatus(triggerId) {
      // 删除该条件
      this.autoTrig_deleteCondition(triggerId, true);
    },
    // 选中验证条件
    select_conditions(value) {
      this.workflowFn_data.validatorType = value;
    },
    // 选中指定状态框
    select_conditionsStatus() {
      this.workflowFn_data.parameters.conditions_statusId =
        this.conditions_Status_value;
    },
    // 选中字段为空
    select_conditionsEmpty() {
      this.workflowFn_data.parameters.conditions_empty_fieldId =
        this.conditions_Empty_value;
    },
    // 选中指定职位
    select_conditionsJob() {
      this.workflowFn_data.parameters.conditions_jobId =
        this.conditions_job_value;
    },
    // 验证条件：选中已保存的指定状态
    select_on_conditionsStatus(validatorId, flag) {
      // 删除该条件
      this.conditions_deleteCondition(validatorId, flag);
    },
    // 选中字段：值的切换字段框
    select_conditions_appoint_field() {
      this.workflowFn_data.parameters.conditions_fieldId =
        this.conditions_appoint_field;
      this.conditions_appoint_value = "";

      /* 对单选项、多选项字段进行处理 */
      this.field_extraData = "";
      let nowfieldId = this.conditions_appoint_field;
      this.workflow_field_arr.forEach((item) => {
        if (item.fieldId == nowfieldId) {
          this.field_extraData = item.extraData;
        }
      });
    },
    // 选中字段：已保存值的切换字段框
    select_conditions_on_appoint_field(validatorId, flag) {
      // 删除该条件
      this.conditions_deleteCondition(validatorId, flag);
    },
    // 选择多用户
    select_users() {
      this.workflowFn_data.parameters.conditions_usersId =
        this.conditions_appoint_users;
    },
    // 验证条件：选中已保存的多用户
    select_on_conditionsUsers(validatorId, flag) {
      // 删除该条件
      this.conditions_deleteCondition(validatorId, flag);
    },
    // 字段相同改变
    conditions_common_field1Change() {
      this.conditions_common_field2 = "";
    },
    // 选中后处理
    select_processing(value) {
      this.workflowFn_data.postHandlerType = value;
    },
    // 后处理：选中指定状态
    select_processingStatus() {
      this.workflowFn_data.parameters.processing_statusId =
        this.processing_Status_value;
    },
    // 后处理：选中已保存的指定状态
    select_on_processingStatus(postHandlerId, flag) {
      // 删除该条件
      this.processing_deleteCondition(postHandlerId, flag);
    },
    // 后处理：选中已保存的指定状态
    select_on_processingAssignee(postHandlerId, flag) {
      // 删除该条件
      this.processing_deleteCondition(postHandlerId, flag);
    },
    // 后处理：选中字段
    select_processing_appoint_field() {
      this.workflowFn_data.parameters.processing_statusId =
        this.processing_appoint_field;

      this.processing_appoint_value = "";
      /* 对单选项、多选项字段进行处理 */
      this.field_extraData_1 = "";
      let nowfieldId = this.processing_appoint_field;
      this.workflow_field_arr.forEach((item) => {
        if (item.fieldId == nowfieldId) {
          this.field_extraData_1 = item.extraData;
        }
      });
    },
    // 获取并更新工作流触发器列表
    get_update_workflow_list() {
      get_workflow_list(
        this.workflowFn_data.projectId,
        this.workflowFn_data.workflowId
      ).then(
        (res) => {
          console.log(res, "789");

          res.forEach((el) => {
            if (el.triggerType == "NODE_RELATION_STATUS_BARRIER") {
              this.back_file = el.parameters.fileTypeId;
              this.back_status = el.parameters.statusIdList;
            }
          });
          this.workflowFn_trigger_typeListAll = res;
          // 获取该线路自动触发条件
          this.workflowFn_trigger_typeListNow = [];
          this.workflowFn_trigger_typeListSelect = [];
          let select_triggerType = [];
          this.workflowFn_trigger_typeListAll.forEach((item, index) => {
            // 获取已添加的触发条件
            if (
              item.fromStatusId == this.workflowFn_data.fromStatusId &&
              item.toStatusId == this.workflowFn_data.toStatusId
            ) {
              // 在所有触发类型中添加该项目
              let typeListAll_triggerType = item.triggerType;
              select_triggerType.push(item.triggerType);
              let typeListAll_triggerId = item.triggerId;
              let typeListAll_statusId = item.parameters.statusIdList || "";
              this.workflowFn_trigger_typeList.forEach((item, index) => {
                if (item.triggerType == typeListAll_triggerType) {
                  let arrayTem = { ...item };
                  arrayTem["statusId"] = typeListAll_statusId;
                  arrayTem["triggerId"] = typeListAll_triggerId;
                  this.workflowFn_trigger_typeListNow.push(arrayTem);
                }
              });
            }
          });
          // 更新下拉框
          this.workflowFn_trigger_typeList.forEach((item) => {
            if (!select_triggerType.includes(item.triggerType)) {
              this.workflowFn_trigger_typeListSelect.push(item);
            }
          });

          // 初始化
          this.autoTrig_workflowInit();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 获取并更新工作流验证条件列表
    get_update_workflow_conditionsList() {
      get_conditions_list(
        this.workflowFn_data.projectId,
        this.workflowFn_data.workflowId
      ).then(
        (res) => {
          this.workflowFn_conditions_typeListAll = res;
          this.workflowFn_conditions_typeListNow = [];
          this.workflowFn_conditions_typeListSelect = [];
          let select_conditionsType = [];
          this.workflowFn_conditions_typeListAll.forEach((item) => {
            if (
              item.fromStatusId == this.workflowFn_data.fromStatusId &&
              item.toStatusId == this.workflowFn_data.toStatusId
            ) {
              // 在所有验证条件类型中添加该项目
              let typeListAll_conditionsType = item.validatorType;
              select_conditionsType.push(item.validatorType);
              let typeListAll_validatorId = item.validatorId;
              let typeListAll_conditionsStatusId = item.parameters.statusId;
              let typeListAll_conditionsFieldId = item.parameters.fieldId;
              let typeListAll_conditionsFieldValue = item.parameters.value;
              let typeListAll_conditionsUsersId = item.parameters.users;
              let typeListAll_conditionsField1 = item.parameters.field1;
              let typeListAll_conditionsField2 = item.parameters.field2;
              let typeListAll_conditionsEmptyFieldId = item.parameters.fieldId;
              let typeListAll_conditionsFieldName = "";
              let typeListAll_conditionsField1Name = "";
              let typeListAll_conditionsField2Name = "";
              let typeListAll_conditionsStatusIdName = "";
              let typeListAll_conditionsEmptyFieldIdName = "";
              let typeListAll_conditionsUsersIdName = [];
              let typeListAll_conditionsFieldIdNames = [];
              let typeListAll_conditionsJobsId = item.parameters.jobIds;
              let typeListAll_conditionsJobsIdNames = [];
              this.field_arr.forEach((item1) => {
                if (item1.fieldId == item.parameters.fieldId) {
                  typeListAll_conditionsFieldName = item1.name;
                }
                if (item1.fieldId == typeListAll_conditionsField1) {
                  typeListAll_conditionsField1Name = item1.name;
                }
                if (item1.fieldId == typeListAll_conditionsField2) {
                  typeListAll_conditionsField2Name = item1.name;
                }
                if (item1.fieldId == typeListAll_conditionsEmptyFieldId) {
                  typeListAll_conditionsEmptyFieldIdName = item1.name;
                }
              });
              this.NOT_EMPTY_role_arr.forEach((item) => {
                if (item.fieldId == typeListAll_conditionsEmptyFieldId) {
                  typeListAll_conditionsEmptyFieldIdName = item.name;
                }
              });
              this.workflow_list.forEach((item1) => {
                if (item1.statusId == item.parameters.statusId) {
                  typeListAll_conditionsStatusIdName = item1.name;
                }
              });
              if (typeListAll_conditionsUsersId) {
                this.userList.forEach((item) => {
                  if (typeListAll_conditionsUsersId.includes(item.accountId)) {
                    typeListAll_conditionsUsersIdName.push(item.nickname);
                  }
                });
                typeListAll_conditionsUsersIdName.join(",");
              }
              if (item.validatorType == "FIELD_EQUALS") {
                if (Array.isArray(typeListAll_conditionsFieldValue)) {
                  // 多用户
                  this.userList.forEach((item) => {
                    if (
                      typeListAll_conditionsFieldValue.includes(item.accountId)
                    ) {
                      typeListAll_conditionsFieldIdNames.push(item.nickname);
                    }
                  });
                  typeListAll_conditionsUsersIdName.join(",");
                } else {
                  this.userList.forEach((item) => {
                    if (typeListAll_conditionsFieldValue == item.accountId) {
                      typeListAll_conditionsFieldIdNames.push(item.nickname);
                    }
                  });

                  typeListAll_conditionsFieldIdNames.join("");
                }
              }
              if (typeListAll_conditionsJobsId) {
                this.jobAuthorities.forEach((item) => {
                  if (typeListAll_conditionsJobsId.includes(item.jobId)) {
                    typeListAll_conditionsJobsIdNames.push(item.name);
                  }
                });
                typeListAll_conditionsJobsIdNames.join(",");
              }

              this.workflowFn_conditions_typeList.forEach((item) => {
                if (item.validatorType == typeListAll_conditionsType) {
                  let arrayTem = { ...item };
                  arrayTem["statusId"] = typeListAll_conditionsStatusId;
                  arrayTem["validatorId"] = typeListAll_validatorId;
                  arrayTem["fieldId"] = typeListAll_conditionsFieldId;
                  arrayTem["value"] = typeListAll_conditionsFieldValue;
                  arrayTem["usersId"] = typeListAll_conditionsUsersId;
                  arrayTem["field1"] = typeListAll_conditionsField1;
                  arrayTem["field2"] = typeListAll_conditionsField2;
                  arrayTem["fieldName"] = typeListAll_conditionsFieldName;
                  arrayTem["field1Name"] = typeListAll_conditionsField1Name;
                  arrayTem["field2Name"] = typeListAll_conditionsField2Name;
                  arrayTem["statusName"] = typeListAll_conditionsStatusIdName;
                  arrayTem["usersName"] = typeListAll_conditionsUsersIdName;
                  arrayTem["usersNames"] = typeListAll_conditionsFieldIdNames;
                  arrayTem["emptyName"] =
                    typeListAll_conditionsEmptyFieldIdName;
                  arrayTem["jobIdNames"] = typeListAll_conditionsJobsIdNames;
                  this.workflowFn_conditions_typeListNow.push(arrayTem);
                }
              });
            }
          });

          // 更新下拉框
          this.workflowFn_conditions_typeList.forEach((item) => {
            if (
              !select_conditionsType.includes(item.validatorType) ||
              item.validatorType == "FIELD_EQUALS" ||
              item.validatorType == "TWO_FIELD_EQUALS" ||
              item.validatorType == "STATUS_BARRIER" ||
              item.validatorType == "APPOINT_USERS" ||
              item.validatorType == "FIELD_NOT_EMPTY" ||
              item.validatorType == "USER_JOB_EQUALS"
            ) {
              this.workflowFn_conditions_typeListSelect.push(item);
            }
          });

          // 初始化
          this.conditions_workflowInit();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 获取并更新工作流后处理列表
    get_update_workflow_processing() {
      get_processing_list(
        this.workflowFn_data.projectId,
        this.workflowFn_data.workflowId
      ).then(
        (res) => {
          this.workflowFn_processing_typeListAll = res;
          // 获取该线路自动触发条件
          this.workflowFn_processing_typeListNow = [];
          this.workflowFn_processing_typeListSelect = [];
          let select_processingType = [];
          this.workflowFn_processing_typeListAll.forEach((item) => {
            if (
              item.fromStatusId == this.workflowFn_data.fromStatusId &&
              item.toStatusId == this.workflowFn_data.toStatusId
            ) {
              // 在所有验证条件类型中添加该项目
              let typeListAll_processingType = item.postHandlerType;
              select_processingType.push(item.postHandlerType);
              let typeListAll_processingFieldId = item.parameters.fieldId;
              let typeListAll_processingValue = item.parameters.value;
              let typeListAll_processingStatusId = item.parameters.statusId;
              let typeListAll_processingPostHandlerId = item.postHandlerId;
              let typeListAll_processingAssignee = item.parameters.assignee;

              let typeListAll_processingFieldIdName = "";
              this.field_arr.forEach((item1) => {
                if (item.parameters.fieldId == item1.fieldId) {
                  typeListAll_processingFieldIdName = item1.name;
                }
              });
              this.workflowFn_processing_typeList.forEach((item) => {
                if (item.postHandlerType == typeListAll_processingType) {
                  let arrayTem = { ...item };
                  arrayTem["statusId"] = typeListAll_processingStatusId;
                  arrayTem["fieldId"] = typeListAll_processingFieldId;
                  arrayTem["value"] = typeListAll_processingValue;
                  arrayTem["postHandlerId"] =
                    typeListAll_processingPostHandlerId;
                  arrayTem["fieldName"] = typeListAll_processingFieldIdName;
                  arrayTem["assignee"] = typeListAll_processingAssignee;
                  typeListAll_processingPostHandlerId;
                  this.workflowFn_processing_typeListNow.push(arrayTem);
                }
              });
            }
          });
          // 更新下拉框
          this.workflowFn_processing_typeList.forEach((item) => {
            if (
              !select_processingType.includes(item.postHandlerType) ||
              item.postHandlerType == "FIELD_MODIFY"
            ) {
              this.workflowFn_processing_typeListSelect.push(item);
            }
          });

          // 初始化
          this.processing_workflowInit();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 添加工作流验证条件
    conditions_addCondition(flag) {
      workflow_payFeatureValid(
        this.workflowFn_data.projectId,
        this.workflowFn_data.workflowId
      ).then((res) => {
        if (res) {
          // 可以使用
          // 用户组添加不予处理
          if (this.workflowFn_data.validatorType == "APPOINT_USER_GROUPS") {
            this.$message({
              type: "warning",
              message: this.$t("editFileType.workflow.dialog.tip11"),
            });
            return;
          }
          // 对提交数据进行处理
          let {
            fromStatusId,
            toStatusId,
            validatorType,
            workflowId,
            parameters,
          } = this.workflowFn_data;
          let conditions_statusId = parameters.conditions_statusId;
          let conditions_fieldId = parameters.conditions_fieldId;
          let conditions_usersId = parameters.conditions_usersId;
          let conditions_empty_fieldId = parameters.conditions_empty_fieldId;
          let conditions_jobId = parameters.conditions_jobId;
          let data = {
            fromStatusId,
            toStatusId,
            validatorType,
            workflowId,
            parameters: {},
          };
          if (this.workflowFn_data.validatorType == "FIELD_EQUALS") {
            // 提交 字段=值 类型
            data.parameters.fieldId = conditions_fieldId;
            data.parameters.value = this.conditions_appoint_value;
          } else if (this.workflowFn_data.validatorType == "STATUS_BARRIER") {
            // 添加指定状态类型
            data.parameters.statusId = conditions_statusId;
          } else if (this.workflowFn_data.validatorType == "APPOINT_USERS") {
            // 指定几个用户
            if (flag == "add_select_users") {
              // 菜单栏修改
              let usersData = [];
              conditions_usersId.forEach((item1) => {
                this.userList.forEach((item2) => {
                  if (item1 == item2.nickname) {
                    usersData.push(item2.accountId);
                  }
                });
              });
              data.parameters.users = usersData;
            } else {
              // 直接添加
              data.parameters.users = conditions_usersId;
            }
          } else if (this.workflowFn_data.validatorType == "TWO_FIELD_EQUALS") {
            data.parameters.field1 = this.conditions_common_field1;
            data.parameters.field2 = this.conditions_common_field2;
          } else if (this.workflowFn_data.validatorType == "FIELD_NOT_EMPTY") {
            data.parameters.fieldId = conditions_empty_fieldId;
          } else if (this.workflowFn_data.validatorType == "USER_JOB_EQUALS") {
            data.parameters.jobIds = conditions_jobId;
          }
          add_workflow_conditions(this.workflowFn_data.projectId, data).then(
            (res) => {
              this.isConditions_loading = true;

              this.get_update_workflow_conditionsList();
              this.conditions_value = "";
              this.conditions_appoint_users = [];
              this.conditions_appoint_field = "";
              this.conditions_appoint_value = "";
              this.conditions_common_field1 = "";
              this.conditions_common_field2 = "";
              this.conditions_Status_value = "";
              this.conditions_Empty_value = "";
              this.conditions_job_value = [];
              // this.conditions_appoint_users = [];

              // this.conditions_common_field1 = "";
              // this.conditions_common_field2 = "";
              // 清空应提交的数据
              this.workflowFn_data.parameters = {};
            }
          );
        } else {
          this.$message({
            duration: 0,
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: `<span>${this.$t("certificate.Please")}<a href="${
              window.location.origin
            }/profile/certificate_management?target=workflow" target="_blank" style="color: blue">${this.$t(
              "certificate.CertificateManagement"
            )}</a>${this.$t("certificate.InstallComponents")}</span>`,
          });
        }
      });
    },
    // 删除验证条件
    conditions_deleteCondition(validatorId, flag) {
      delete_workflow_conditions(
        this.workflowFn_data.projectId,
        validatorId
      ).then((res) => {
        this.isConditions_loading = true;
        this.conditions_appoint_users_label = [];
        this.conditions_appoint_field = "";
        this.conditions_appoint_field_label = "";
        // 添加需要更新的参数
        this.workflowFn_data.parameters.conditions_statusId =
          this.conditions_Status_value;
        this.workflowFn_data.parameters.conditions_usersId =
          this.conditions_appoint_users;
        if (flag == "add_select_status") {
          // 添加指定状态
          // 更新状态
          this.workflowFn_data.validatorType = "STATUS_BARRIER";
          this.conditions_addCondition();
        } else if (flag == "add_appoint_field") {
          // 字段 = 值
          // 更新状态
          this.workflowFn_data.validatorType = "FIELD_EQUALS";
          this.conditions_addCondition();
        } else if (flag == "add_select_users") {
          // 添加用户
          this.workflowFn_data.validatorType = "APPOINT_USERS";
          if (this.conditions_appoint_users.length > 0) {
            this.conditions_addCondition(flag);
          } else {
            this.get_update_workflow_conditionsList();
            this.conditions_appoint_users = [];
          }
        } else {
          this.conditions_appoint_users = [];
          this.conditions_appoint_field = "";
          this.conditions_appoint_value = "";
          this.conditions_common_field1 = "";
          this.conditions_common_field2 = "";
          this.conditions_Status_value = "";
          this.get_update_workflow_conditionsList();
        }
      });
    },
    // 添加工作流后处理
    processing_addCondition() {
      workflow_payFeatureValid(
        this.workflowFn_data.projectId,
        this.workflowFn_data.workflowId
      ).then((res) => {
        if (res) {
          // 可以使用
          // 对提交数据进行处理
          let {
            fromStatusId,
            toStatusId,
            validatorType,
            workflowId,
            parameters,
            postHandlerType,
          } = this.workflowFn_data;
          let processing_statusId = parameters.processing_statusId;
          let processing_field = parameters.processing_statusId;
          // let conditions_fieldId = parameters.conditions_fieldId;
          // let conditions_usersId = parameters.conditions_usersId;
          let data = {
            fromStatusId,
            toStatusId,
            postHandlerType,
            workflowId,
            parameters: {},
          };
          if (this.workflowFn_data.postHandlerType == "CHILD_STATUS_MODIFY") {
            // 指定状态
            data.parameters.statusId = processing_statusId;
          } else if (this.workflowFn_data.postHandlerType == "FIELD_MODIFY") {
            // 字段 = 值
            data.parameters.fieldId = processing_field;
            data.parameters.value = this.processing_appoint_value;
          } else if (
            this.workflowFn_data.postHandlerType ==
            "ASSIGNEE_MODIFY_TO_SPECIFIED_USER"
          ) {
            data.parameters.assignee = this.processing_Assignee_value;
          }

          add_workflow_processing(this.workflowFn_data.projectId, data).then(
            (res) => {
              this.isProcessing_loading = true;

              this.get_update_workflow_processing();
              this.processing_value = "";
              this.processing_appoint_field = "";
              this.processing_appoint_value = "";
              this.processing_Assignee_value = "";
              this.workflowFn_data.parameters = {};
            }
          );
        } else {
          this.$message({
            duration: 0,
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: `<span>${this.$t("certificate.Please")}<a href="${
              window.location.origin
            }/profile/certificate_management?target=workflow" target="_blank" style="color: blue">${this.$t(
              "certificate.CertificateManagement"
            )}</a>${this.$t("certificate.InstallComponents")}</span>`,
          });
        }
      });
    },
    // 删除后处理
    processing_deleteCondition(postHandlerId, flag) {
      delete_workflow_processing(
        this.workflowFn_data.projectId,
        postHandlerId
      ).then((res) => {
        this.isProcessing_loading = true;
        // 获取参数
        this.workflowFn_data.parameters.processing_statusId =
          this.processing_Status_value;
        if (flag == "add_select_status") {
          // 指定状态修改
          this.workflowFn_data.postHandlerType = "CHILD_STATUS_MODIFY";
          this.processing_addCondition();
        } else if (flag == "add_select_assignee") {
          // 指定状态修改
          this.workflowFn_data.postHandlerType =
            "ASSIGNEE_MODIFY_TO_SPECIFIED_USER";
          this.processing_addCondition();
        } else {
          this.processing_appoint_field = "";
          this.processing_appoint_value = "";
          this.get_update_workflow_processing();
        }
      });
    },

    // 编辑按钮
    edit_workflow() {
      this.isEdit = true;
      this.workflow_isEdit = false;
      this.workflow_isNodeAdd = false;
      this.isedge_move = false;
      this.workflow_isAllIn = false;
    },
    // 退出编辑按钮
    edit_workflow_quit() {
      if (
        this.workflow_isEdit ||
        this.workflow_isNodeAdd ||
        this.workflow_isAllIn ||
        this.isedge_move
      ) {
        // 有编辑的未保存

        this.edit_unSave_dialog = true;
      } else {
        // 无编辑
        // this.send_workflow(true);
        this.isEdit = false;
        this.workflow_isEdit = false;
      }
    },
    // 编辑中确认保存
    save_workflow_inEdit() {
      this.edit_unSave_dialog = false;
      this.save_workflow();
      // this.send_workflow(true);
      if (this.workflow_isEdit_pushPath) {
        this.$emit("ispush_from12", false);
      }
    },
    // 编辑中取消保存
    save_workflow_cancleInEdit() {
      this.edit_unSave_dialog = false;

      if (this.workflow_isEdit_pushPath) {
        this.$emit("ispush_from12", true);

        let temPath = this.workflow_isEdit_pushPath;

        this.$router.push(temPath);
        return;
      } else if (this.ischange_level) {
        this.workflow_isEdit = false;
        this.workflow_isNodeAdd = false;
        this.workflow_isAllIn = false;
        this.isedge_move = false;
        this.$emit("gonext1");
      } else {
        this.$router.go(0);
      }
    },
    // 关闭编辑框
    edit_unSave_dialogClose() {
      this.edit_unSave_dialog = false;
      // if (this.workflow_isEdit_pushPath) {
      //   let temPath = this.workflow_isEdit_pushPath;
      //   this.$emit("ispush_from12", true);
      //   this.$router.push(temPath);
      // }
    },

    use_isEdit() {
      this.graph.on("edge:added", ({ cell, options }) => {
        if (this.isEdit) {
          this.workflow_isEdit = true;
        }
      });
      this.graph.on("edge:removed", ({ cell, options }) => {
        if (this.isEdit) {
          this.workflow_isEdit = true;
        }
      });
      this.graph.on("'node:added", ({ cell, options }) => {
        if (this.isEdit) {
          this.workflow_isEdit = true;
        }
      });
      this.graph.on("node:removed", ({ cell, options }) => {
        if (this.isEdit) {
          this.workflow_isEdit = true;
        }
      });
    },
    // 改变验证条件逻辑
    change_judgeLogic() {
      // 修改校验器的判断逻辑
      change_workflow_conditions_judgeLogic(this.workflowFn_data.projectId, {
        fromStatusId: this.workflowFn_data.fromStatusId,
        toStatusId: this.workflowFn_data.toStatusId,
        workflowId: this.workflowFn_data.workflowId,
        judgeLogic: this.judgeLogic,
      }).then((res) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.old_item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.workflow_edit {
  text-align: left;
  margin-bottom: 20px;
  .workflow_form {
    text-align: left;
    display: flex;
    align-items: center;
    .el-dropdown-link {
      cursor: pointer;
      color: rgba(51, 102, 144, 1);
    }
    .el-form-item {
      margin-right: 30px;
    }
    .save_new_template {
      color: rgba(51, 102, 144, 1);
      cursor: pointer;
    }
    .save_chart {
      &:hover {
        color: rgba(48, 100, 143, 1);
      }
    }
    ::v-deep .workflow_form_edit {
      > .el-form-item__content {
        justify-content: flex-end;
        .el-button--default {
          border-radius: 4px;
          border: 1px solid rgba(1, 76, 170, 1);
          span {
            color: rgba(1, 76, 170, 1);
          }
          .el-icon-edit-outline {
            color: rgba(1, 76, 170, 1);
          }
        }
      }
    }
  }
  .workflow_render {
    position: relative;
    .tools {
      position: absolute;
      right: 20px; /*no*/
      top: 10px; /*no*/
      padding: 20px;
      z-index: 2;
      .el-checkbox {
        display: none;
      }
      .active_item {
        display: block;
      }
    }
  }
  .new_status_dialog {
    .el-form {
      .el-select {
        width: 100%;
      }
    }
  }
  .confirm_status_dialog {
    .status_matching {
      font-size: 16px;
      display: flex;
      flex-direction: column;
      .item {
        height: 70px;
        line-height: 70px;
        border-bottom: 1px solid rgba(229, 229, 229, 1);
        border-left: 1px solid rgba(229, 229, 229, 1);
        border-right: 1px solid rgba(229, 229, 229, 1);
        display: flex;
        & > * {
          flex: 1;
          text-align: center;
        }
        .el-form-item {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 0px;
          .el-select {
            width: 135px;
            ::v-deep .el-input {
              font-size: 16px;
            }
          }
        }
      }
      .title {
        height: 40px;
        line-height: 40px;
        border-top: 1px solid rgba(229, 229, 229, 1);
      }
    }
  }
}
::v-deep .drawerWorkflowFn {
  background-color: rgb(240, 242, 245);
  font-size: 16px;

  .el-collapse-item__header {
    flex-direction: row-reverse;
    width: 30%;
    padding-left: 20px;
    .el-icon-arrow-right:before {
      background-color: rgba(229, 229, 229, 1);
      padding: 2px;
    }
    > div {
      flex: 4;
    }
  }
  .drawerWorkflowFn-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 34px;
    margin: 0 auto;
    .auto-trig-contentAll,
    .conditions-contentAll,
    .post-processing-contentAll {
      .auto-trig-main {
        display: flex;
        flex-direction: column;

        .auto-trig-content {
          display: flex;
          align-items: center;
          padding-bottom: 23px;
          width: 100%;
          .auto-trig-content1 {
            width: 100%;
            display: flex;
            align-items: center;
          }
          .auto-trig-content2 {
            width: 100%;
            display: flex;
            flex-direction: column;
            .auto-trig-content2_1 {
              display: flex;
              align-items: center;
            }
            .el-select {
              width: 50%;
              padding-top: 10px;
            }
          }
          .auto-trig-contentMain {
            flex: 1;
            font-size: 14px;
            font-weight: 400;
            color: rgba(128, 128, 128, 1);
            margin-right: 15px;
            padding: 7px 0 10px 15px;
            border-radius: 4px;
            border: 1px solid #dcdfe6;
            box-sizing: border-box;
          }
        }
      }
      .change-conditions {
        width: 180px;
        padding-bottom: 19px;
        .el-input__inner {
          color: rgba(1, 76, 170, 1);
          font-weight: 400;
          font-size: 16px;
        }
      }
    }

    .dialogWorkflowFn-display-footer {
      display: flex;

      flex-direction: column;
      .dialogWorkflowFn-display-footer-select {
        display: flex;
        div:nth-child(1) {
          flex: 4;
        }
        div:nth-child(2) {
          flex: 1;
        }
      }
      .dialogWorkflowFn-select {
        display: flex;
        flex-direction: column;
        .conditions_field {
          font-size: 14px;
          font-weight: 400;
          color: rgba(128, 128, 128, 1);
          .el-select {
            width: 100%;
          }
          div:nth-child(1) {
            flex: 1;
          }
          div:nth-child(2) {
            flex: 4;
          }
          div:nth-child(3) {
            flex: 0.2;
          }
          div:nth-child(4) {
            flex: 1;
          }
          div:nth-child(5) {
            flex: 4;
          }
        }
        .conditions_common_field {
          font-size: 14px;
          font-weight: 400;
          color: rgba(128, 128, 128, 1);
          div:nth-child(1) {
            flex: 1;
          }
          div:nth-child(2) {
            flex: 4;
          }
          div:nth-child(3) {
            flex: 0.2;
          }
          div:nth-child(4) {
            flex: 1;
          }
          div:nth-child(5) {
            flex: 4;
          }
        }
      }
      .buttonContent {
        padding-top: 19px;
        .el-button {
          border-radius: 4px;
          border: 1px solid rgba(1, 76, 170, 1);
          span {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 1px;
            color: rgba(1, 76, 170, 1);
            text-align: left;
            height: 100%;
          }
          .el-icon-plus {
            color: rgba(1, 76, 170, 1);
          }
        }
        // .el-button:hover {
        //   display: none;
        // }
      }
      .el-select {
        flex: 1;
        padding-right: 10px;
      }
    }

    .deleteImgIcon {
      cursor: pointer;
    }
    > div {
      width: 100%;
      padding-bottom: 20px;
      .el-collapse {
        border-radius: 12px;
        overflow: hidden;
        background-color: #fff;
      }
    }
    .el-collapse-item__content {
      padding: 0 12px 29px 65px;
    }
  }
  .el-collapse-itemTitle {
    // padding: 12px;
    height: 100%;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
  }
  .drawerWorkflowFn-title {
    font-size: 24px;
    font-weight: 400;
    color: rgba(56, 56, 56, 1);
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .el-icon-close {
    margin: 0;
  }
}
::v-deep .el-drawer.rtl {
  width: 50% !important;
}
.el-select-dropdown__item {
  white-space: pre-wrap;
  overflow-x: hidden;
  height: auto;
}
</style>
<style lang="scss">
#container {
  min-height: 500px;
}
.el-dropdown-menu.el-popper.status_list {
  height: 300px;
  padding-bottom: 0px;
  position: relative;
  .add_btn {
    width: 100%;
    background-color: #fff;
    position: absolute;
    bottom: 0px;
  }
  .el-scrollbar {
    .el-scrollbar__wrap {
      overflow-x: hidden;
      .el-scrollbar__view {
        margin-bottom: 36px; /*no*/
      }
    }
  }
}
.eventStop {
  pointer-events: none;
}
.select_own .el-select-dropdown__list {
  padding: 0;
  margin: 0;
  li:nth-child(1) {
    background-color: rgba(77, 160, 255, 0.35);
    border-radius: 6px 6px 0px 0px;
  }
}
.el-popper .popper__arrow,
.el-popper .popper__arrow::after {
  display: none;
}
.user_select {
  display: flex;
  align-items: center;
  .avatar {
    display: flex;
  }
  .select_item {
    margin-left: 14px;
  }
}
</style>
